import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";

export function TableTipoReunion ({ listTipoReunionShow, editPadre }) {

    const accionesTemplate = (rowData) => {
        return(
            <>
                <Button severity="warning" type="button" onClick={() => editPadre(rowData)}>
                    <i className="pi pi-pencil"></i>
                </Button>
            </>
        )
    }
    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <DataTable value={listTipoReunionShow} paginator rows={10} sortField="id" sortOrder={1} stripedRows showGridlines>
                        <Column header="Tipo de reunión" field="Nombre" filter filterPlaceholder="Tipo reunión" sortable></Column>
                        <Column header="View" field="View" sortable></Column>
                        <Column header="Acciones" body={accionesTemplate}></Column>
                    </DataTable>
                </div>
            </div>
        </div>
    )
}