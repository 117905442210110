import React, { useEffect, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useReunionMeet } from '../../hooks';
import { useTipoReunionMeet } from '../../hooks';
import { TableReunion } from '../../components/Skymeet/TableReunion';
import { ModalReunion } from '../../components/Skymeet/ModalReunion';

export function ReunionPage() {
    const [refresh, setRefresh] = useState(false);
    const [idReunion, setIdReunion] = useState(0);

    const toast = useRef();

    const {listTipoReunion} = useTipoReunionMeet();

    const { saveReunion, getReunion, listReunion, loadingReunion, editReunion } = useReunionMeet();
    

    const onRefresh = () => setRefresh((prev) => !prev);

    useEffect(() => {
        document.title = "Tipos de reuniones";
        (async () => {
            await getReunion();
        })();
    }, [refresh])

    const [visible, setVisible] = useState(false);

    const openDialog = () => {
        setVisible(true);
    };

    const closeDialog = () => {
        setVisible(false);
    };

    return (
        <>
            <Toast ref={toast} />
            <div className="grid">
                <div className="col-12">
                    <div className="card my-3" style={{display: "flex", justifyContent: 'space-between', width: '100%'}}>
                        <h5>Reuniones</h5>
                        <Button severity="primary" onClick={openDialog}>
                            <i className="pi pi-plus"></i>
                            &nbsp;
                            Agregar reunión
                        </Button>
                        {/* <p>Agrega los tipos de reuniones que pueden ser registradas</p>
                        <p>Antes de crear un tipo de reunión, recuerda validar los ya existentes.</p> */}

                    </div>
                </div>
            </div>

            <Dialog
                header="Crear reunión"
                visible={visible}
                onHide={closeDialog}
                style={{ width: '85vw', height: 'auto' }}
            >                 
                <ModalReunion 
                    onSubmit={() => {
                        closeDialog();
                        toast.current.show({ 
                            severity: 'success', 
                            summary: 'Exito', 
                            detail: 'Reunión creada existosamente!'
                        });
                        onRefresh();
                    }}
                />                
            </Dialog>

            {loadingReunion ? (
                <div className="grid">
                    <div className="col-12 text-center">
                        <i className="pi pi-spinner pi-spin" style={{ fontSize: '5rem' }}></i>
                    </div>
                </div>
            ) : (
                <TableReunion listReunionShow={listReunion} />
            )}
        </>
    );
}

