import { useState } from "react"
import { addParticipanteApi, getParticipanteApi, updateParticipanteApi } from "../api/skymeet/participante"
import { useAuth } from "./useAuth"

export function useParticipanteMeet () {

    const [error, setError] = useState(false)
    const [loadingParticipante, setLoadingParticipante] = useState(false)
    const [listParticipante, setListParticipante] = useState(null)

    const {auth} = useAuth()

    const saveParticipante = async (FormData) => {
        try {
            setLoadingParticipante(true)

            const result = await addParticipanteApi(FormData, auth.token)           

            setLoadingParticipante(false)

            return result;
            
        } catch (error) {
            setLoadingParticipante(false)
            setError(error)
        }
    }

    const editParticipante = async (FormData, id) => {
        try {
            setLoadingParticipante(true)

            await updateParticipanteApi(FormData, id, auth.token)

            setLoadingParticipante(false)
            
        } catch (error) {
            setLoadingParticipante(false)
            setError(error)
        }
    }

    const getParticipante = async () => {
        try {
            setLoadingParticipante(true)
            const response_api = await getParticipanteApi(auth.token)
            setListParticipante(response_api)
            setLoadingParticipante(false)
            
        } catch (error) {
            setLoadingParticipante(false)
            setError(error)
            
        }
    }

    return {
        error,
        loadingParticipante,
        listParticipante,
        saveParticipante,
        getParticipante,
        editParticipante

    }

}