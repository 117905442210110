import React from 'react';

export function ReportRosterPage() {
    return (
        <>
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <h3>Reporte Master Roster</h3>
                    </div>
                </div>
            </div>

            <div className='grid'>
                <div className='col-12'>
                    <iframe width="100%" height="700" frameborder="0" scrolling="no" src="https://skycomcallcenter.sharepoint.com/sites/SkycomWFM/_layouts/15/Doc.aspx?sourcedoc={26f2862a-bb52-4bfb-bcd8-c1a2b45a520b}&action=embedview&ActiveCell='Roster'!D2&wdDownloadButton=True&wdInConfigurator=True&wdInConfigurator=True"></iframe>
                </div>
            </div>
        </>
    );
}
