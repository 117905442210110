import React, {useState, useEffect, useRef} from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from 'primereact/inputtext';
import { InputNumber } from "primereact/inputnumber"; 
import { Dialog } from "primereact/dialog";
import { CascadeSelect } from 'primereact/cascadeselect';
import { useTipoExcepcionMeet } from "../../hooks";
import { useExcepcionRMeet } from "../../hooks";
import { useParticipanteMeet } from "../../hooks";
import { useNotaMeet } from "../../hooks";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Toast } from 'primereact/toast';
import { InputTextarea } from "primereact/inputtextarea";
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import {useAuth, useProfile, useNotificacion} from "../../hooks"
import moment from "moment";
// import { useUser } from "../../hooks";
// import { Badge } from "primereact/badge";

export function ModalExcepcion( {onSubmit} ) {

    const { getTipoExcepcion, listTipoExcepcion } = useTipoExcepcionMeet();
    const { saveExcepcionR } = useExcepcionRMeet();

    const toast = useRef();

    const { auth } = useAuth()

    const [ExcepcionTy, setExcepcion] = useState(null);
    

    useEffect(() => {
        (async () => {
            await getTipoExcepcion();
        })();
    }, []);
    
    const formik = useFormik({
        initialValues: initialValues(auth.me),
        validationSchema: Yup.object(validationSchema()),
        onSubmit: async (formValue) => {
            console.log(formValue);
            const responseN = await saveExcepcionR(formValue);
            
            // onCleanField();
            onSubmit();

            // toast.current.show({ severity: 'success', summary: 'Exito', detail: 'Excepción creada existosamente!' });
            // onclose();
        },
        validate: () => {
            if (formik.errors.Comentario || formik.errors.FechaInicio || formik.errors.FechaFinal || formik.errors.TipoExcepcion) {
                console.log(formik.errors);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debes diligenciar todos los campos' });
            }
        },
        validateOnChange: false
    });

    const onCleanField = () => {
        // formik.setFieldValue('Badge', '');
        // formik.setFieldValue('NombreBadge', '');
        formik.setFieldValue('Comentario', '');
        formik.setFieldValue('FechaInicio', '');
        formik.setFieldValue('FechaFinal', '');
        // formik.setFieldValue('TipoExcepcion', '');  
        // formik.setFieldValue('Cuenta', 'SkycomTest');
        // setIdExcepcion(0);
    };

    function initialValues(data) {     
        return {
            Badge: data.username,
            NombreBadge: data.first_name + " " + data.last_name,
            Comentario: '',
            FechaInicio: '',
            FechaFinal: '',
            TipoExcepcion: '0',
        };
    }
    
    function validationSchema() {
        return {
            Comentario: Yup.string().required(true),
            FechaInicio: Yup.string().required(true),
            FechaFinal: Yup.string().required(true),
            TipoExcepcion: Yup.string().required(true),
        };
    }

    const changeT = (n) => {
        formik.setFieldValue('TipoExcepcion', n)
    }  
    
    const [dateI, setDateI] = useState(null);
    const [dateF, setDateF] = useState(null);

    const changeD = (d, val) => {
        const fecha = d;
        const valoration = val;        
        if (fecha){
            const date = moment(fecha).format('YYYY-MM-DD');
            if (valoration === "I"){
                formik.setFieldValue('FechaInicio', date);
                setDateI(fecha);
                setDateF(fecha);
                formik.setFieldValue('FechaFinal', date);
            } else {
                formik.setFieldValue('FechaFinal', date);
            }            
            
        }
    }

    return (
        <>
            <Toast ref={toast} />
            <div className="grid">
                <div className="col-12">
                <hr></hr>
                <form onSubmit={formik.handleSubmit}>
                    <InputText id="Badge" name="Badge" value={formik.values.Badge} onChange={formik.handleChange} className="w-full5" disabled hidden />
                    <InputText id="NombreBadge" name="NombreBadge" value={formik.values.NombreBadge} onChange={formik.handleChange} className="w-full5" disabled hidden />
                    <InputNumber id="TipoExcepcion" name="TipoExcepcion" value={formik.values.TipoExcepcion} onChange={formik.handleChange} className="w-full5" disabled style={{ display: 'none' }} />
                    <InputText id="FechaInicio" name="FechaInicio" value={formik.values.FechaInicio} className="w-full5" disabled hidden  />
                    <InputText id="FechaFinal" name="FechaFinal" value={formik.values.FechaFinal} className="w-full5" disabled hidden  />
                    <div className="grid my-5">
                        <div className="col-12">
                            <span className="p-float-label">
                                <Dropdown id="TipoEx" name="TipoExc" value={ExcepcionTy} onChange={(e) => {setExcepcion(e.value); changeT(e.value.id);}} options={listTipoExcepcion} optionLabel="Nombre" 
                                    placeholder="Selecciona un tipo de reunión" className="w-full md:w-40rem" />  
                                <label htmlFor="TipoEx">
                                    Selecciona el tipo de excepción <b style={{ color: 'red' }}>*</b>
                                </label>
                            </span>
                        </div> 
                    </div>
                    <div className="grid my-5">                       
                        <div className="col-6">
                            <span className="p-float-label">
                                <Calendar id="FechaIni" name="FechaIni" value={dateI} onChange={(e) => {changeD(e.value, "I");}} style={{ width: '100%' }} showIcon />
                                <label htmlFor="FechaIni">
                                    Fecha de inicio <b style={{ color: 'red' }}>*</b>
                                </label>                                
                            </span>
                        </div>   
                        <div className="col-6">
                            <span className="p-float-label">
                                <Calendar id="FechaFin" name="FechaFin" value={dateF} onChange={(e) => {changeD(e.value, "F");}} style={{ width: '100%' }} minDate={dateI} disabled={!dateI} showIcon />
                                <label htmlFor="FechaFin">
                                    Fecha final <b style={{ color: 'red' }}>*</b>
                                </label>                                
                            </span>
                        </div>
                    </div>
                    <div className="grid my-5">
                        <div className="col-12">
                            <span className="p-float-label"> 
                                <InputText id="Empleado" name="Empleado" /*value={formik.values.Badge} onChange={formik.handleChange}*/ style={{ width: '100%' }} className="w-full5" />
                                <label htmlFor="Empleado">                                    
                                    Empleado <b style={{ color: 'red' }}>*</b>
                                </label>
                            </span>
                        </div> 
                    </div>
                    
                    <h6>Comentario <b style={{ color: 'red' }}>*</b></h6>
                    <div className="grid my-4">  
                        <div className="col-12">
                            <span className="p-float-label">
                                <InputTextarea id="Comentario" name="Comentario" value={formik.values.Comentario} onChange={formik.handleChange} rows={4} cols={85} style={{ width: '100%' }} />
                                {/* <label htmlFor="6">Comentario</label> */}
                            </span>
                        </div>
                    </div>
                    <hr></hr>
                    <div className="col-12 text-right">
                        <Button type="submit" label='Crear excepción' severity="success" >&nbsp; 
                            <i className="pi pi-check"></i>
                        </Button>                        
                    </div>
                    
                </form>
                </div>
            </div>
        </>        

    )
}