import { useState } from 'react';
import { size } from 'lodash';
import moment from "moment"
import { getEmpleadosApi, getSkyEmpleadosApi, getSkyEmpleadosPersoApi } from '../api/skyroster/empleado';
import { getEmpleadoByFilterApi, getDetalleEmpleadoApi, getSkyViewEmpleadoApi } from '../api/skyroster/detalleempleado';
import { getPropiedadesEmpleadoApi } from '../api/skyroster/propiedadempleado';
import { getTipoMarcasApi } from '../api/skytracking/tipomarca';
import { getCuentasApi } from '../api/skyroster/cuenta';
import { getAsignacionCuentasApi } from '../api/skyroster/asignacioncuenta';
import { getLobsApi } from '../api/skyroster/lob';
import { getSubLobsApi } from '../api/skyroster/sublob';
import { getPlazasApi } from '../api/skyroster/plaza';
import { getTipoApsApi } from '../api/skyap/tipoap';
import { useAuth } from './useAuth';
import { weekYearDate } from '../utilities/constants';

export function useCommon() {
    const [error, setError] = useState(false);
    const [loadingCommon, setLoadingCommon] = useState(false);
    const [listEmpleados, setListEmpleados] = useState(null);
    const [listPausas, setListPausas] = useState(null);
    const [listTiposAp, setTiposAp] = useState(null);
    const [listPlazas, setListPlazas] = useState(null);
    const [listPropiedades, setListPropiedades] = useState(null);

    const [listCuentas, setListCuentas] = useState(null);
    const [listLob, setListLob] = useState(null);
    const [listSubLob, setListSubLob] = useState(null);

    const [listSupervisores, setListSupervisores] = useState(null);
    const [listHrGeneralist, setHrGeneralist] = useState(null);
    const [listAccountManager, setListAccountManager] = useState(null);
    const [listDirectores, setListDirectores] = useState(null);

    const { auth } = useAuth();

    const getSkyViewEmpleados = async () => {
        try {
            setLoadingCommon(true)

            let response_view = []

            if (auth.me.is_staff){

                response_view = await getSkyViewEmpleadoApi(auth.token);
            }else{

                switch (auth.me.username) {
                    case "LTSC6059":
                        response_view = await getSkyViewEmpleadoApi(auth.token, "LTSC6032");
                        break;
                    case "LT6719":
                        response_view = await getSkyViewEmpleadoApi(auth.token, "LT4552");
                        break;
                    case "LT4571":
                        response_view = await getSkyViewEmpleadoApi(auth.token, "LT5768");
                        break;
                
                    case "LT5263":
                        response_view = await getSkyViewEmpleadoApi(auth.token, "LT5768");
                        break;
                
                    case "LT5028":
                        response_view = await getSkyViewEmpleadoApi(auth.token, "LT5768");
                        break;
                
                    case "LT5039":
                        response_view = await getSkyViewEmpleadoApi(auth.token, "LT5768");
                        break;
                
                    case "LT3734":
                        response_view = await getSkyViewEmpleadoApi(auth.token, "LT5768");
                        break;
                
                    case "LT3668":
                        response_view = await getSkyViewEmpleadoApi(auth.token, "LT5768");
                        break;
                
                    case "LT6488":
                        response_view = await getSkyViewEmpleadoApi(auth.token, "LT5768");
                        break;
                
                    default:
                        response_view = await getSkyViewEmpleadoApi(auth.token, auth.me.username);
                        break;
                }

            }


            setLoadingCommon(false)

            return response_view
        } catch (error) {
            setLoadingCommon(false)
            setError(error)
        }
    }

    const getEmpleados = async () => {
        try {
            setLoadingCommon(true);

            let response_empleado = [];
            let result_empleados = [];

            if (auth.me.is_staff) {
                //response_empleado = await getEmpleadosApi(auth.token, weekYearDate());
                await getSkyEmpleados()
            } else {
                const cuentas_asignadas = await getAsignacionCuentasApi(auth.token, auth.me.id);
                let cuentas = [];

                if (size(cuentas_asignadas) > 0) {
                    for await (const cuenta of cuentas_asignadas) {
                        cuentas.push(cuenta.Cuenta);
                    }

                    const response_empleados_asignados = await getEmpleadoByFilterApi(auth.token, '', 3, weekYearDate(), '', '', cuentas.join('%2C'));

                    response_empleado = response_empleados_asignados;
                } else {
                    response_empleado = [];
                }

                for await (const empleado of response_empleado) {
                    const id_empleado = empleado.empleado_data ? empleado.empleado_data.id : empleado.id;
                    const badge_empleado = empleado.empleado_data ? empleado.empleado_data.Badge : empleado.Badge;
    
                    const response_nombre = await getDetalleEmpleadoApi(auth.token, 2, id_empleado);
                    const response_id_cuenta = await getDetalleEmpleadoApi(auth.token, 3, id_empleado);
                    const response_cuenta = await getCuentasApi(auth.token, 1, response_id_cuenta[0].Valor);
    
                    result_empleados.push({
                        value: id_empleado,
                        name: `${badge_empleado} ${response_nombre[0]?.Valor} - ${response_cuenta[0]?.Nombre}`
                    });
                }
    
                setListEmpleados(result_empleados);

            }


            setLoadingCommon(false);
        } catch (error) {
            console.log(error);
            setLoadingCommon(false);
            setError(error);
        }
    };

    const getSkyEmpleados = async () => {
        try {
            setLoadingCommon(true)
            const response_empleados = await getSkyEmpleadosApi(auth.token)
            const listEmpleados = []

            for await (const emp of Object.entries(response_empleados)){
                listEmpleados.push({
                    value: emp[1].idEmpleado,
                    name: `${emp[1].Badge} ${emp[1].Nombre} - ${emp[1].Cuenta}`
                })
            }

            setListEmpleados(listEmpleados)

            setLoadingCommon(false)
        } catch (error) {
            setLoadingCommon(false)
            setError(error)
        }
    }

    const getSkyEmpleados_badge = async () => {
        try {
            setLoadingCommon(true)
            const response_empleados = await getSkyEmpleadosPersoApi(auth.token)
            const listEmpleados = []

            for await (const emp of response_empleados){
                listEmpleados.push({
                    value: emp.Badge.Badge,
                    name: `${emp.Badge.Badge} ${emp[2]} - ${emp.Cuenta.Nombre}`
                })
            }

            setListEmpleados(listEmpleados)

            setLoadingCommon(false)
        } catch (error) {
            setLoadingCommon(false)
            setError(error)
        }
    }

    const getPausasFilter = async (Tipo, Modal, Excepcion) => {
        try {
            setLoadingCommon(true);
            const list_pausas = [];
            const response = await getTipoMarcasApi(auth.token, Tipo, Modal, Excepcion);

            for await (const pausa of response) {
                list_pausas.push({
                    name: pausa.NombreTipoMarca,
                    definicion: pausa.Definicion,
                    id_pausa: pausa.id
                });
            }

            setListPausas(list_pausas);
            setLoadingCommon(false);
        } catch (error) {
            setLoadingCommon(false);
            setError(error);
        }
    };

    const getPausasFilter_response = async (Tipo, Modal, Excepcion) => {
        try {
            setLoadingCommon(true);
            const response = await getTipoMarcasApi(auth.token, Tipo, Modal, Excepcion);
            setLoadingCommon(false);

            return response;
        } catch (error) {
            setLoadingCommon(false);
            setError(error);
        }
    };

    const getTipoAp = async () => {
        try {
            setLoadingCommon(true);

            const response = await getTipoApsApi(auth.token);
            let listTiposAp_arr = [];

            for await (const tipoAp of response) {
                listTiposAp_arr.push({
                    name: tipoAp.Nombre,
                    value: tipoAp.id
                });
            }

            setTiposAp(listTiposAp_arr);

            setLoadingCommon(false);
        } catch (error) {
            setLoadingCommon(false);
            setError(error);
        }
    };

    const getPlazas = async () => {
        try {
            setLoadingCommon(true);
            const plazas_response = await getPlazasApi(auth.token, true, "");
            let listPlazas_arr = [];
            for await (const plaza of plazas_response) {
                listPlazas_arr.push({
                    name: plaza.Nombre,
                    value: plaza.id
                });
            }

            setListPlazas(listPlazas_arr);
            setLoadingCommon(false);
        } catch (error) {
            setLoadingCommon(false);
            setError(error);
        }
    };

    const getPropiedades = async () => {
        try {
            setLoadingCommon(true);
            const propiedades_response = await getPropiedadesEmpleadoApi(auth.token, true, "");
            let listPropiedades_arr = [];
            for await (const propiedad of propiedades_response) {
                listPropiedades_arr.push({
                    name: propiedad.Propiedad,
                    value: propiedad.id
                });
            }

            setListPropiedades(listPropiedades_arr);
            setLoadingCommon(false);
        } catch (error) {
            setLoadingCommon(false);
            setError(error);
        }
    };

    const getCuenta = async () => {
        try {
            setLoadingCommon(true);
            const cuentas_response = await getCuentasApi(auth.token, 1);
            let listCuentas_arr = [];
            for await (const cuenta of cuentas_response) {
                listCuentas_arr.push({
                    name: cuenta.Nombre,
                    value: cuenta.id
                });
            }

            setListCuentas(listCuentas_arr);
            setLoadingCommon(false);
        } catch (error) {
            setLoadingCommon(false);
            setError(error);
        }
    };

    const getLob = async (idCuenta) => {
        try {
            setLoadingCommon(true);
            const Lob_response = await getLobsApi(auth.token, 1, idCuenta);
            let listLob_arr = [];
            for await (const lob of Lob_response) {
                listLob_arr.push({
                    name: lob.Lob,
                    value: lob.id
                });
            }

            setListLob(listLob_arr);
            setLoadingCommon(false);
        } catch (error) {
            setLoadingCommon(false);
            setError(error);
        }
    };

    const getSubLob = async (idLob) => {
        try {
            setLoadingCommon(true);
            const SubLob_response = await getSubLobsApi(auth.token, 1, idLob);
            let listSubLob_arr = [];
            for await (const SubLob of SubLob_response) {
                listSubLob_arr.push({
                    name: SubLob.SubLob,
                    value: SubLob.id
                });
            }

            setListSubLob(listSubLob_arr);
            setLoadingCommon(false);
        } catch (error) {
            setLoadingCommon(false);
            setError(error);
        }
    };

    const getSupervisores = async () => {
        try {
            setLoadingCommon(true);
            const supervisores_response = await getEmpleadoByFilterApi(auth.token, 3, 6, moment().week(), '');
            let listSupervisores_arr = [];

            listSupervisores_arr.push({
                name: 'LT2262 - Kevin Misael Alvarez Espinoza',
                badge: "LT2262",
                nombre_sup: 'Kevin Misael Alvarez Espinoza'
            });

            for await (const sup of supervisores_response) {
                const nombre_response = await getDetalleEmpleadoApi(auth.token, 2, sup.empleado_data.id);

                listSupervisores_arr.push({
                    name: `${sup.empleado_data.Badge} - ${nombre_response[0].Valor}`,
                    badge: sup.empleado_data.Badge,
                    nombre_sup: nombre_response[0].Valor
                });
            }

            setListSupervisores(listSupervisores_arr);
            setLoadingCommon(false);
        } catch (error) {
            setLoadingCommon(false);
            setError(error);
        }
    };

    const getHrGeneralist = async () => {
        try {
            setLoadingCommon(true);
            const supervisores_response = await getEmpleadoByFilterApi(auth.token, 6, 6, moment().week(), '');
            let listSupervisores_arr = [];
            for await (const sup of supervisores_response) {
                const nombre_response = await getDetalleEmpleadoApi(auth.token, 2, sup.empleado_data.id);

                listSupervisores_arr.push({
                    name: `${sup.empleado_data.Badge} - ${nombre_response[0].Valor}`,
                    badge: sup.empleado_data.Badge,
                    nombre_sup: nombre_response[0].Valor
                });
            }

            setHrGeneralist(listSupervisores_arr);
            setLoadingCommon(false);
        } catch (error) {
            setLoadingCommon(false);
            setError(error);
        }
    };

    const getAccountManagers = async () => {
        try {
            setLoadingCommon(true);
            const supervisores_response = await getEmpleadoByFilterApi(auth.token, 4, 6, weekYearDate(), '');
            let listAccountManager_arr = [];
            for await (const sup of supervisores_response) {
                const nombre_response = await getDetalleEmpleadoApi(auth.token, 2, sup.empleado_data.id);

                listAccountManager_arr.push({
                    name: `${sup.empleado_data.Badge} - ${nombre_response[0].Valor}`,
                    badge: sup.empleado_data.Badge,
                    nombre_sup: nombre_response[0].Valor
                });
            }

            setListAccountManager(listAccountManager_arr);
            setLoadingCommon(false);
        } catch (error) {
            setLoadingCommon(false);
            setError(error);
        }
    };

    const getDirectores = async () => {
        try {
            setLoadingCommon(true);
            const supervisores_response = await getEmpleadoByFilterApi(auth.token, 5, 6, moment().week(), '');
            let listDirectores_arr = [];
            for await (const sup of supervisores_response) {
                const nombre_response = await getDetalleEmpleadoApi(auth.token, 2, sup.empleado_data.id);

                listDirectores_arr.push({
                    name: `${sup.empleado_data.Badge} - ${nombre_response[0].Valor}`,
                    badge: sup.empleado_data.Badge,
                    nombre_sup: nombre_response[0].Valor
                });
            }

            setListDirectores(listDirectores_arr);
            setLoadingCommon(false);
        } catch (error) {
            setLoadingCommon(false);
            setError(error);
        }
    };

    const getRolSystem = async (username) => {
        try {
            setLoadingCommon(true);
            const rol_response = await getEmpleadoByFilterApi(auth.token, "", 6, weekYearDate(), username);
            
            setLoadingCommon(false);
            return rol_response
        } catch (error) {
            setLoadingCommon(false);
            setError(error);
        }
    };

    const getDetalleEmpleado = async (Badge, Week) => {
        try {
            setLoadingCommon(true);
            const rol_response = await getEmpleadoByFilterApi(auth.token, "", "", Week, Badge);
            
            setLoadingCommon(false);
            return rol_response
        } catch (error) {
            setLoadingCommon(false);
            setError(error);
        }
    };

    const getPlazaValid = async () => {
        try {
            setLoadingCommon(true);
            const rol_response = await getEmpleadoByFilterApi(auth.token, "", 6, weekYearDate(), auth.me.username);
            
            setLoadingCommon(false);
            return rol_response
        } catch (error) {
            setLoadingCommon(false);
            setError(error);
        }
    };

    const getAllBadges = async () => {
        try {
            setLoadingCommon(true) 

            const hoy = new Date();
            const SemanaActual = moment(hoy).week();

            const response_badges = await getEmpleadosApi(auth.token, SemanaActual)

            setLoadingCommon(false) 

            return response_badges
        } catch (error) {
           setLoadingCommon(false) 
           setError(error)
        }
    }

    return {
        error,
        loadingCommon,
        listEmpleados,
        listPausas,
        listTiposAp,
        listPlazas,
        listCuentas,
        listLob,
        listSubLob,
        listSupervisores,
        listDirectores,
        listAccountManager,
        listPropiedades,
        listHrGeneralist,
        getEmpleados,
        getPausasFilter,
        getPausasFilter_response,
        getTipoAp,
        getPlazas,
        getCuenta,
        getLob,
        getSubLob,
        getSupervisores,
        getAccountManagers,
        getDirectores,
        getPropiedades,
        getRolSystem,
        getPlazaValid,
        getSkyEmpleados,
        getAllBadges,
        getSkyEmpleados_badge,
        getSkyViewEmpleados,
        getHrGeneralist,
        getDetalleEmpleado
    };
}
