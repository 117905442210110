import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { format } from "date-fns"

export function TableTipoExcepcion ({ listTipoExcepcionShow, editPadre }) {

    const accionesTemplate = (rowData) => {
        return(
            <>
                <Button severity="warning" type="button" onClick={() => editPadre(rowData)}>
                    <i className="pi pi-pencil"></i>
                </Button>
            </>
        )
    }
    const formatDate = (dateString) => {
        return format(new Date(dateString), 'yyyy-MM-dd');
    };
    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <DataTable value={listTipoExcepcionShow} paginator rows={10} sortField="id" sortOrder={1} stripedRows showGridlines>
                        <Column header="Tipo de excepción" field="Nombre" filter filterPlaceholder="Tipo excepción" sortable></Column>
                        {/* <Column header="Fecha de creación" field="created_at" body={rowData => formatDate(rowData.created_at)} sortable></Column> */}
                        <Column header="Acciones" headerStyle={{ textAlign: 'center'}} body={accionesTemplate} /*bodyStyle={{ textAlign: 'center' }}*/></Column>
                    </DataTable>
                </div>
            </div>
        </div>
    )
}