import { useState } from "react"
import { addExcepcionRApi, getExcepcionRApi, updateExcepcionRApi } from "../api/skymeet/excepcion"
import { useAuth } from "./useAuth"

export function useExcepcionRMeet () {

    const [error, setError] = useState(false)
    const [loadingExcepcionR, setLoadingExcepcionR] = useState(false)
    const [listExcepcionR, setListExcepcionR] = useState(null)

    const {auth} = useAuth()

    const saveExcepcionR = async (FormData) => {
        try {
            setLoadingExcepcionR(true)

            const result = await addExcepcionRApi(FormData, auth.token)           

            setLoadingExcepcionR(false)

            return result;
            
        } catch (error) {
            setLoadingExcepcionR(false)
            setError(error)
        }
    }

    const editExcepcionR = async (FormData, id) => {
        try {
            setLoadingExcepcionR(true)

            await updateExcepcionRApi(FormData, id, auth.token)

            setLoadingExcepcionR(false)
            
        } catch (error) {
            setLoadingExcepcionR(false)
            setError(error)
        }
    }

    const getExcepcionR = async () => {
        try {
            setLoadingExcepcionR(true)
            const response_api = await getExcepcionRApi(auth.token)
            setListExcepcionR(response_api)
            setLoadingExcepcionR(false)
            
        } catch (error) {
            setLoadingExcepcionR(false)
            setError(error)
            
        }
    }

    return {
        error,
        loadingExcepcionR,
        listExcepcionR,
        saveExcepcionR,
        getExcepcionR,
        editExcepcionR

    }

}