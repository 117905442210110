import React, { useEffect, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useExcepcionRMeet } from '../../hooks';
import { useTipoExcepcionMeet } from '../../hooks';
// import { TableExcepcion } from '../../components/Skymeet/TableExcepcion';
import { TableExcepcion } from '../../components/Skymeet';
// import { ModalExcepcion } from '../../components/Skymeet/ModalExcepcion';
import { ModalExcepcion } from '../../components/Skymeet';

export function ReportePage() {
    const [refresh, setRefresh] = useState(false);
    const [idExcepcion, setIdExcepcion] = useState(0);

    const toast = useRef();

    const {listTipoExcepcion} = useTipoExcepcionMeet();

    const { saveExcepcionR, getExcepcionR, listExcepcionR, loadingExcepcion, editExcepcion } = useExcepcionRMeet();

    const onRefresh = () => setRefresh((prev) => !prev);

    useEffect(() => {
        document.title = "Tipos de Excepciones";
        (async () => {
            await getExcepcionR();
        })();
    }, [refresh]);

    const [visible, setVisible] = useState(false);

    const openDialog = () => {
        setVisible(true);
    };

    const closeDialog = () => {
        setVisible(false);
    };

    return (
        <>
            <Toast ref={toast} />
            <div className="grid">
                <div className="col-12">
                    <div className="card my-3" style={{display: "flex", justifyContent: 'space-between', width: '100%'}}>
                        <h5>Reportes</h5>
                        <h5>Reportes</h5>
                        <h5>Reportes</h5>
                    </div>
                </div>
            </div>

            <Dialog
                header="Crear excepción"
                visible={visible}
                onHide={closeDialog}
                style={{ width: '85vw', height: 'auto' }}
            >                 
                <ModalExcepcion
                    onSubmit={() => {
                        closeDialog();
                        toast.current.show({ 
                            severity: 'success', 
                            summary: 'Exito', 
                            detail: 'Excepción creada existosamente!'
                        });
                        onRefresh();
                    }}
                />
            </Dialog>

            {loadingExcepcion ? (
                <div className="grid">
                    <div className="col-12 text-center">
                        <i className="pi pi-spinner pi-spin" style={{ fontSize: '5rem' }}></i>
                    </div>
                </div>
            ) : (
                <TableExcepcion listExcepcionShow={listExcepcionR} />
            )}
        </>
    );
}
