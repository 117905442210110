import { useState } from "react"
import { addReunionApi, getReunionApi, updateReunionApi } from "../api/skymeet/reunion"
import { useAuth } from "./useAuth"

export function useReunionMeet () {

    const [error, setError] = useState(false)
    const [loadingReunion, setLoadingReunion] = useState(false)
    const [listReunion, setListReunion] = useState(null)

    const {auth} = useAuth()

    const saveReunion = async (FormData) => {
        try {
            setLoadingReunion(true)

            const result = await addReunionApi(FormData, auth.token)           

            setLoadingReunion(false)

            return result;
            
        } catch (error) {
            setLoadingReunion(false)
            setError(error)
        }
    }

    const editReunion = async (FormData, id) => {
        try {
            setLoadingReunion(true)

            await updateReunionApi(FormData, id, auth.token)

            setLoadingReunion(false)
            
        } catch (error) {
            setLoadingReunion(false)
            setError(error)
        }
    }

    const getReunion = async () => {
        try {
            setLoadingReunion(true)
            const response_api = await getReunionApi(auth.token)
            setListReunion(response_api)
            setLoadingReunion(false)
            
        } catch (error) {
            setLoadingReunion(false)
            setError(error)
            
        }
    }

    return {
        error,
        loadingReunion,
        listReunion,
        saveReunion,
        getReunion,
        editReunion

    }

}