import React, {useState, useEffect, useRef} from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from 'primereact/inputtext';
import { InputNumber } from "primereact/inputnumber"; 
import { Dialog } from "primereact/dialog";
import { CascadeSelect } from 'primereact/cascadeselect';
import { useTipoReunionMeet } from "../../hooks";
import { useReunionMeet } from "../../hooks";
import { useParticipanteMeet } from "../../hooks";
import { useNotaMeet } from "../../hooks";
import { useFormik, FieldArray } from 'formik';
import * as Yup from 'yup';
import { Toast } from 'primereact/toast';
import { InputTextarea } from "primereact/inputtextarea";
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import {useAuth, useProfile, useNotificacion} from "../../hooks"
import moment from "moment";
// import { useUser } from "../../hooks";
// import { Badge } from "primereact/badge";

export function ModalReunion( {onSubmit} ) {

    const { getTipoReunion, listTipoReunion } = useTipoReunionMeet();
    const { saveReunion, getReunion, listReunion, loadingReunion, editReunion } = useReunionMeet();
    const { saveParticipante } = useParticipanteMeet();
    const { saveNota } = useNotaMeet();

    const toast = useRef();
    const [idReunion, setIdReunion] = useState(0);

    const { auth } = useAuth()

    const [reunionTy, setReunion] = useState(null);
    const [reunionTy2, setReunion2] = useState(null);
    // const { user } = useProfile()

    // const {getListAsignacionNotificacion, listAsignacionNotificaciones} = useNotificacion()

    // const [apiResponse, setApiResponse] = useState(null);
    

    useEffect(() => {
        (async () => {
            await getTipoReunion();
        })();
    }, []);

    const dict_bd = {
        NivelesRiesgo: 2,
        Nota: 3,
        Kpi: 4,
        Meta: 5,
        Valor: 6,
        Recomendaciones: 7,
        Compromisos: 8,
        TipoROLA: 9,
        AtengoLowPerformance: 10,
        AccionesRealizadas: 11,
        AccionesRealizar: 12,
        NecesidadesFormacion: 13,
        FechaMejora: 14,
        ApoyosSolicitados: 15,
        ComentariosLowPerformance: 16,
        AgradaSkycom: 17,
        AreasMejorar: 18,
        RelacionesCompa: 19,
        RelacionQA: 20,
        RelacionSUP: 21,
        RelacionOPSManager: 22,
        ConsideradoRenunciar: 23,
        ComentariosLowEngagement: 24,
        Titulo: 25,
        Datetime: 26,
        Account: 27,
        Participant: 28,
        PointDiscussed: 29,
        Remark: 30,
        Responsible: 31,
        Date: 32,
        Reason: 33,
    }
    
    const formik = useFormik({
        initialValues: initialValues(auth.me),
        // validationSchema: Yup.object(validationSchema()),
        onSubmit: async (formValue) => {
            const response = await saveReunion(formValue);
            try {
                formValue.Reunion = response.id                
            } catch (error) {  
                console.log(error)                              
            }
            const responseP = await saveParticipante(formValue);
            for (const key_input of Object.keys(formValue)){
 
                if(dict_bd[key_input] != undefined){
 
                    let data_form_nota = {
                        "Reunion": response.id,
                        "Campo": dict_bd[key_input],
                        "Valor": formValue[key_input],
                        "Badge":  formValue.Badge,
                        "NombreBadge":  formValue.NombreBadge
                    }
                    const responseN = await saveNota(data_form_nota);
   
                    console.log(data_form_nota)
                   
                }
            }

            
            // const responseN = await saveNota(formValue);
            console.log(formValue)
            onSubmit();
            // toast.current.show({ severity: 'success', summary: 'Exito', detail: 'Reunión creada existosamente!' });

            // onCleanField();
            // onclose();
        },
        validate: () => {
            if (formik.errors.Nombre) {
                // toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debes diligenciar todos los campos' });
            }
        },
        validateOnChange: false
    });

    // const onCleanField = () => {
    //     formik.setFieldValue('Nombre', '');
    //     formik.setFieldValue('FechaReunion', '');
    //     formik.setFieldValue('TipoReunion', '');
    //     // formik.setFieldValue('Badge', '');
    //     // formik.setFieldValue('NombreBadge', '');
    //     // formik.setFieldValue('Reunion', '');
    //     formik.setFieldValue('Cuenta', 'SkycomTest');
    // };

    

    const [selectedRisk, setSelectedRisk] = useState(null);
    const risks = [
        { name: 'Low risk', id: 1 },
        { name: 'Medium risk', id: 2 },
        { name: 'High risk', id:3 }
    ];

    const [value, setValue] = useState('');
    // const [dateTime, setDateTime] = useState(null); 

    function initialValues(data) {      
        return {
            Nombre: '',
            FechaReunion: '',
            TipoReunion: '0',
            Badge: data.username,
            NombreBadge: data.first_name + " " + data.last_name,
            Cuenta: 'SkycomTest',
            Reunion: '',
            Nota: '',
            Kpi: 'Attendance',
            Meta: '0',
            Valor: '0',
            NivelesRiesgo: null,
        };
    }
    
    function validationSchema() {
        return {
            Nombre: Yup.string().required(true),
            // FechaReunion: Yup.string().required(true),
            TipoReunion: Yup.string().required(true),
            Badge: Yup.string().required(true),
            Cuenta: Yup.string().required(true),
        };
    }

    const changeT = (n) => {
        formik.setFieldValue('TipoReunion', n)
    }

    const changeR = (r) => {
        formik.setFieldValue('Reunion', r)
    }

    const changeD = (d) => {
        const fecha = d;
        if (fecha){
            const date = moment(fecha).format('YYYY-MM-DD');
            formik.setFieldValue('FechaReunion', date)
        }
    }

    

    const formReu = [
        { id: "one_on_one", formR:
            <div>
                <form onSubmit={formik.handleSubmit}>
                    <InputText id="Badge" name="Badge" value={formik.values.Badge} onChange={formik.handleChange} className="w-full5" disabled />
                    <InputText id="NombreBadge" name="NombreBadge" value={formik.values.NombreBadge} onChange={formik.handleChange} className="w-full5" disabled />
                    <InputText id="Cuenta" name="Cuenta" value={formik.values.Cuenta} onChange={formik.handleChange} className="w-full5" disabled />
                    <InputNumber id="TipoReunion" name="TipoReunion" value={formik.values.TipoReunion} onChange={formik.handleChange} className="w-full5" disabled />
                    <Calendar id="FechaR" name="FechaR" onChange={(e) => {changeD(e.value);}} /*onChange={formik.handleChange; changeT(e.value.id);}*/ dateFormat="yy-mm-dd" className="w-full5"  />
                    <InputText id="FechaReunion" name="FechaReunion" value={formik.values.FechaReunion} className="w-full5"  />
                    <InputText id="Reunion" name="Reunion" value={formik.values.Reunion} className="w-full5"  />
                    <InputNumber id="NivelesRiesgo" name="NivelesRiesgo" value={formik.values.NivelesRiesgo} onChange={formik.handleChange} className="w-full5" disabled />
                    <div className="grid my-5">
                        <div className="col-6">
                            <span className="p-float-label">
                                <InputText id="Nombre" name="Nombre" value={formik.values.Nombre} onChange={formik.handleChange} className="w-full" />
                                <label htmlFor="Nombre">
                                    Nombre reunión <b style={{ color: 'red' }}>*</b>
                                </label>
                            </span>
                        </div>                        
                        <div className="col-6">
                            <span className="p-float-label">
                                <InputText id="1" name="1" className="w-full" />
                                <label htmlFor="1">
                                    Participante <b style={{ color: 'red' }}>*</b>
                                </label>
                            </span>
                        </div>                       
                    </div> 
                    <hr></hr>
                    <h6><b>ROLA</b></h6>
                    <div className="grid my-5">                        
                        <div className="col-6">
                        <Dropdown id="NivelesR" name="NivelesR" value={selectedRisk} onChange={(e) => {setSelectedRisk(e.value); changeR(e.value.id)}} options={risks} optionLabel="name" 
                            placeholder="Selecciona el riesgo" className="w-full md:w-100%" />
                        </div>
                        <div className="col-6">
                            <span className="p-float-label">
                                <InputTextarea id="Nota" value={formik.values.Nota} onChange={formik.handleChange} style={{ width: '100%' }} rows={3} cols={40} />
                                <label htmlFor="Nota">Nota</label>
                            </span>
                        </div>
                    </div>
                    <hr></hr>
                    <h6><b>Performance Management</b></h6>
                    <div className="grid my-5">                        
                        <div className="col-4">
                        <span className="p-float-label">
                            <InputText id="Kpi" name="Kpi" className="w-full" value={formik.values.Kpi} onChange={formik.handleChange} disabled/>
                            <label htmlFor="Kpi">
                                Kpis <b style={{ color: 'red' }}>*</b>
                            </label>
                        </span>
                        </div>
                        <div className="col-4">
                            <span className="p-float-label">
                                <InputNumber id="Meta" name="Meta" value={formik.values.Meta} onValueChange={formik.handleChange} min={0} max={100} className="w-full" />
                                <label htmlFor="Meta">
                                    Goal <b style={{ color: 'red' }}>*</b>
                                </label>
                            </span>
                        </div>
                        <div className="col-4">
                            <span className="p-float-label">
                                <InputNumber id="Valor" name="Valor" value={formik.values.Valor} onValueChange={formik.handleChange} min={0} max={100} className="w-full" />
                                <label htmlFor="Valor">
                                    Value <b style={{ color: 'red' }}>*</b>
                                </label>
                            </span>
                        </div>
                    </div>
                    <hr></hr>
                    <h6><b>Leadership</b></h6>
                    <div className="grid my-5">  
                        <div className="col-12">
                            <span className="p-float-label">
                                <InputTextarea id="Recomendaciones" name="Recomendaciones" value={formik.values.Recomendaciones} onChange={formik.handleChange} rows={4} cols={85} style={{ width: '100%' }} />
                                <label htmlFor="5">Recommendations / Examples / Good Practices <b style={{ color: 'red' }}>*</b></label>
                            </span>
                        </div>
                    </div>
                    <hr></hr>
                    <h6><b>Commitments</b></h6>
                    <div className="grid my-5">  
                        <div className="col-12">
                            <span className="p-float-label">
                                <InputTextarea id="ComentariosLowPerformance" name="ComentariosLowPerformance" value={formik.values.ComentariosLowperformance} onChange={formik.handleChange} rows={4} cols={85} style={{ width: '100%' }} />
                                <label htmlFor="6">Commitments <b style={{ color: 'red' }}>*</b></label>
                            </span>
                        </div>
                    </div>
                    <hr></hr>
                    <div className="col-12 text-right">
                        <Button type="submit" label='Crear reunión' severity="success" >&nbsp; 
                            <i className="pi pi-check"></i>
                        </Button>                        
                    </div>
                    
                </form>
                              
            </div>
         },
        { id: "moms", formR:
            <div>
                <form onSubmit={formik.handleSubmit}>
                    <InputText id="Badge" name="Badge" value={formik.values.Badge} onChange={formik.handleChange} className="w-full5" disabled />
                    <InputText id="NombreBadge" name="NombreBadge" value={formik.values.NombreBadge} onChange={formik.handleChange} className="w-full5" disabled />
                    <InputText id="Cuenta" name="Cuenta" value={formik.values.Cuenta} onChange={formik.handleChange} className="w-full5" disabled />
                    <InputNumber id="TipoReunion" name="TipoReunion" value={formik.values.TipoReunion} onChange={formik.handleChange} className="w-full5" disabled />
                    {/* <Calendar id="FechaR" name="FechaR" onChange={(e) => {changeD(e.value);}}  dateFormat="yy-mm-dd" className="w-full5"  /> */}
                    <InputText id="FechaReunion" name="FechaReunion" value={formik.values.FechaReunion} className="w-full5"  />
                    <InputText id="Reunion" name="Reunion" value={formik.values.Reunion} className="w-full5"  />
                    <InputNumber id="NivelesRiesgo" name="NivelesRiesgo" value={formik.values.NivelesRiesgo} onChange={formik.handleChange} className="w-full5" disabled />
                    <div className="grid my-5">
                        <div className="col-6">
                            <span className="p-float-label">
                                <InputText id="Nombre" name="Nombre" value={formik.values.Nombre} onChange={formik.handleChange} className="w-full" />
                                <label htmlFor="Nombre">
                                    Nombre reunión <b style={{ color: 'red' }}>*</b>
                                </label>
                            </span>
                        </div>                        
                        <div className="col-6">
                            <span className="p-float-label">
                                {/* <InputText id="1" name="1" className="w-full" /> */}
                                <Calendar id="FechaR" name="FechaR" onChange={(e) => {changeD(e.value);}} /*onChange={formik.handleChange; changeT(e.value.id);}*/ dateFormat="yy-mm-dd" className="w-full5" style={{ width: '100%' }} showIcon  />
                                <label htmlFor="FechaR">
                                    Fecha de la reunión <b style={{ color: 'red' }}>*</b>
                                </label>
                            </span>
                        </div>                       
                    </div> 
                    <div className="grid my-5">                        
                        <div className="col-6">
                            <span className="p-float-label">
                                <Dropdown id="TipoEx" name="TipoExc" value={selectedRisk} onChange={(e) => {setSelectedRisk(e.value); changeR(e.value.id)}} options={risks} optionLabel="name" 
                                    placeholder="Selecciona un tipo de reunión" className="w-full md:w-40rem" />  
                                <label htmlFor="TipoEx">
                                    Selecciona la cuenta <b style={{ color: 'red' }}>*</b>
                                </label>
                            </span>
                        </div>
                        <div className="col-6">
                            <span className="p-float-label">
                                <InputText id="1" name="1" className="w-full" />
                                <label htmlFor="1">
                                    Participante <b style={{ color: 'red' }}>*</b>
                                </label>
                            </span>
                        </div>
                    </div>
                    <hr></hr>
                    <h6><b>Points Discussed</b></h6>
                    <div className="grid">
                        <div className="col-2">
                            <Button label='Add Point' severity="info" >&nbsp; 
                                <i className="pi pi-check"></i>
                            </Button>
                        </div>                    
                    </div>
                    <div className="grid my-2">                         
                        <div className="col-10">
                            <span className="p-float-label">
                                <InputTextarea id="username" value={value} onChange={(e) => setValue(e.target.value)} rows={4} cols={50} style={{ width: '100%' }} />
                                <label htmlFor="username">Point</label>
                            </span>                             
                        </div>
                        <div className="col-2 text-center">
                            <Button label='Remove' severity="danger" >&nbsp; 
                            </Button>
                        
                        </div>       
                    </div>
                    <hr></hr>
                    <h6><b>Points Agreed</b></h6>
                    <div className="grid">
                        <div className="col-2">
                            <Button label='Add Point' severity="info" >&nbsp; 
                                <i className="pi pi-check"></i>
                            </Button>
                        </div>                    
                    </div>
                    <div className="grid my-2">                         
                        <div className="col-2">
                            <span className="p-float-label">
                                <InputTextarea id="Remark" name="Remark" value={value} onChange={(e) => setValue(e.target.value)} rows={4} cols={50} style={{ width: '100%' }} />
                                <label htmlFor="username">Remark</label>
                            </span>                             
                        </div>
                        <div className="col-4">
                            <span className="p-float-label">
                                <InputTextarea id="Responsible" name="Responsible" value={value} onChange={(e) => setValue(e.target.value)} rows={4} cols={50} style={{ width: '100%' }} />
                                <label htmlFor="Responsible">Responsible</label>
                            </span>                             
                        </div>
                        <div className="col-4">
                            <span className="p-float-label">
                                <Calendar id="Dat" name="Dat" onChange={(e) => {changeD(e.value);}}  dateFormat="yy-mm-dd" className="w-full5" style={{ width: '100%' }} showIcon  />
                                <label htmlFor="Dat">
                                    Date <b style={{ color: 'red' }}>*</b>
                                </label>
                            </span>                             
                        </div>
                        <div className="col-2 text-center">
                            <Button label='Remove' severity="danger" >&nbsp; 
                            </Button>
                        
                        </div>       
                    </div>
                    <hr></hr>
                    <div className="col-12 text-right">
                        <Button type="submit" label='Crear reunión' severity="success" >&nbsp; 
                            <i className="pi pi-check"></i>
                        </Button>                        
                    </div>                    
                </form>                              
            </div>
            // --------------------------------------------------------------------------
        //     <div>
        //         <form onSubmit={formik.handleSubmit}>
        //             <div className="grid my-5" style={{ width: '100%' }}>
        //                 <div className="col-6">
        //                     <span className="p-float-label">
        //                         <InputText id="Nombre" name="Nombre" className="w-full" />
        //                         <label htmlFor="Nombre">
        //                             Nombre reunión <b style={{ color: 'red' }}>*</b>
        //                         </label>
        //                     </span>
        //                 </div>
        //                 <div className="col-6">
        //                     <span className="p-float-label">
        //                         <Calendar
        //                             name="dataa"
        //                             id="dataa"
        //                             value={dateTime}
        //                             onChange={(e) => setDateTime(e.value)}
        //                             placeholder="Seleccione una fecha y hora"
        //                         />
        //                         <label htmlFor="dataa">Nota</label>
        //                     </span>
        //                 </div>                       
        //             </div> 

        //             <div className="grid my-5">
        //                 <div className="col-6">
        //                     <span className="p-float-label">
        //                         <InputText id="Nombre" name="Nombre" className="w-full" />
        //                         <label htmlFor="Nombre">
        //                            Account <b style={{ color: 'red' }}>*</b>
        //                         </label>
        //                     </span>
        //                 </div>
        //                 <div className="col-6">
        //                     <span className="p-float-label">
        //                         <InputText id="Prefijo" name="Prefijo" className="w-full" />
        //                         <label htmlFor="Prefijo">
        //                             Participante <b style={{ color: 'red' }}>*</b>
        //                         </label>
        //                     </span>
        //                 </div>                       
        //             </div>
        //             <hr></hr>
        //             <h6>Points Discussed</h6>
        //             <div className="grid">
        //                 <div className="col-2">
        //                     <Button label='Add' severity="info" >&nbsp; 
        //                         <i className="pi pi-check"></i>
        //                     </Button>
        //                 </div>
                    
        //             </div>
        //             <div className="grid my-2">                         
        //                 <div className="col-10">
        //                     <span className="p-float-label">
        //                         <InputTextarea id="username" value={value} onChange={(e) => setValue(e.target.value)} rows={4} cols={50} />
        //                         <label htmlFor="username">Point</label>
        //                     </span>                             
        //                 </div>
        //                 <div className="col-2 text-center">
        //                     <Button label='Remove' severity="danger" >&nbsp; 
        //                         {/* <i className="pi pi-check"></i> */}
        //                     </Button>
                            
        //                     </div> 
        //                 {/* <div className="col-4">
        //                     <Button label='Crear reunión' severity="success" >&nbsp; 
        //                         <i className="pi pi-check"></i>
        //                     </Button>
                            
        //                 </div> */}
        //             </div>
        //             {/* <h6>Performance Management</h6>
        //             <div className="grid my-5">                        
        //                 <div className="col-4">
        //                 <span className="p-float-label">
        //                     <InputText id="Nombre" name="Nombre" className="w-full" />
        //                     <label htmlFor="Nombre">
        //                         Kpis <b style={{ color: 'red' }}>*</b>
        //                     </label>
        //                 </span>
        //                 </div>
        //                 <div className="col-4">
        //                     <span className="p-float-label">
        //                         <InputText id="Nombre" name="Nombre" className="w-full" />
        //                         <label htmlFor="Nombre">
        //                             Goal <b style={{ color: 'red' }}>*</b>
        //                         </label>
        //                     </span>
        //                 </div>
        //                 <div className="col-4">
        //                     <span className="p-float-label">
        //                         <InputText id="Nombre" name="Nombre" className="w-full" />
        //                         <label htmlFor="Nombre">
        //                             Value <b style={{ color: 'red' }}>*</b>
        //                         </label>
        //                     </span>
        //                 </div>
        //             </div>
        //             <h6>Leadership</h6>
        //             <div className="grid my-5">  
        //                 <div className="col-12">
        //                     <span className="p-float-label">
        //                         <InputTextarea id="username" value={value} onChange={(e) => setValue(e.target.value)} rows={4} cols={85} />
        //                         <label htmlFor="username">Recommendations / Examples / Good Practices</label>
        //                     </span>
        //                 </div>
        //             </div>
        //             <h6>Commitments</h6>
        //             <div className="grid my-5">  
        //                 <div className="col-12">
        //                     <span className="p-float-label">
        //                         <InputTextarea id="username" value={value} onChange={(e) => setValue(e.target.value)} rows={4} cols={85} />
        //                         <label htmlFor="username">Commitments</label>
        //                     </span>
        //                 </div>
        //             </div>  */}
        //             <hr></hr>
        //             <div className="col-12 text-right">
        //                 <Button label='Crear reunión' severity="success" >&nbsp; 
        //                     <i className="pi pi-check"></i>
        //                 </Button>                        
        //             </div>
                    
        //         </form>
                              
        //     </div>
        // -------------------------------------------
         },
        // { name: 'Rome', id: 14 },
        // { name: 'London', id: 15 },
        // { name: 'Istanbul', id: 16 },
        // { name: 'Paris', id: 'PRS' }
    ];

    

    return (
        <>
            <Toast ref={toast} />
            <div className="grid">
                <div className="col-12">
                    <div className="card flex flex-col justify-content">   
                        <Button label='Selecciona el tipo de reunión' severity="warning" disabled>                                            
                            &nbsp;
                            <i className="pi pi-plus"></i>
                        </Button>   
                        &nbsp;            
                        <Dropdown id="TipoReunion" name="TipoReunion" value={reunionTy} onChange={(e) => {setReunion(e.value); changeT(e.value.id);}} options={listTipoReunion} optionLabel="Nombre" 
                            placeholder="Selecciona un tipo de reunión" className="w-full md:w-20rem" />  
                        &nbsp;   
                        
                    </div>                
                </div>

                <div className="col-12">
                    <div className="card flex flex-col justify-content" style={{ width: '100%' }} >   
                        {reunionTy && (
                            <div style={{ width: '100%' }}>
                                <h5>{reunionTy.Nombre}</h5>

                                {formReu.map(reu => {
                                    if (reunionTy.View === reu.id) { 
                                        return <div key={reu.id}>{reu.formR}</div>;
                                    }
                                    return null ;
                                })}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
        

    )
}