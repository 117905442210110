import { useState } from "react"
import { addTipoExcepcionApi, getTipoExcepcionApi, updateTipoExcepcionApi } from "../api/skymeet/tipoexcepcion"
import { useAuth } from "./useAuth"

export function useTipoExcepcionMeet () {

    const [error, setError] = useState(false)
    const [loadingTipoExcepcion, setLoadingTipoExcepcion] = useState(false)
    const [listTipoExcepcion, setListTipoExcepcion] = useState(null)

    const {auth} = useAuth()

    const saveTipoExcepcion = async (FormData) => {
        try {
            setLoadingTipoExcepcion(true)

            await addTipoExcepcionApi(FormData, auth.token)

            setLoadingTipoExcepcion(false)
            
        } catch (error) {
            setLoadingTipoExcepcion(false)
            setError(error)
        }
    }

    const editTipoExcepcion = async (FormData, id) => {
        try {
            setLoadingTipoExcepcion(true)

            await updateTipoExcepcionApi(FormData, id, auth.token)

            setLoadingTipoExcepcion(false)
            
        } catch (error) {
            setLoadingTipoExcepcion(false)
            setError(error)
        }
    }

    const getTipoExcepcion = async () => {
        try {
            setLoadingTipoExcepcion(true)
            const response_api = await getTipoExcepcionApi(auth.token)
            setListTipoExcepcion(response_api)
            setLoadingTipoExcepcion(false)
            
        } catch (error) {
            setLoadingTipoExcepcion(false)
            setError(error)
            
        }
    }

    return {
        error,
        loadingTipoExcepcion,
        listTipoExcepcion,
        saveTipoExcepcion,
        getTipoExcepcion,
        editTipoExcepcion

    }

}