import { useState } from "react"
import { addTipoReunionApi, getTipoReunionApi, updateTipoReunionApi } from "../api/skymeet/tiporeunion"
import { useAuth } from "./useAuth"

export function useTipoReunionMeet () {

    const [error, setError] = useState(false)
    const [loadingTipoReunion, setLoadingTipoReunion] = useState(false)
    const [listTipoReunion, setListTipoReunion] = useState(null)

    const {auth} = useAuth()

    const saveTipoReunion = async (FormData) => {
        try {
            setLoadingTipoReunion(true)

            await addTipoReunionApi(FormData, auth.token)

            setLoadingTipoReunion(false)
            
        } catch (error) {
            setLoadingTipoReunion(false)
            setError(error)
        }
    }

    const editTipoReunion = async (FormData, id) => {
        try {
            setLoadingTipoReunion(true)

            await updateTipoReunionApi(FormData, id, auth.token)

            setLoadingTipoReunion(false)
            
        } catch (error) {
            setLoadingTipoReunion(false)
            setError(error)
        }
    }

    const getTipoReunion = async () => {
        try {
            setLoadingTipoReunion(true)
            const response_api = await getTipoReunionApi(auth.token)
            setListTipoReunion(response_api)
            setLoadingTipoReunion(false)
            
        } catch (error) {
            setLoadingTipoReunion(false)
            setError(error)
            
        }
    }

    return {
        error,
        loadingTipoReunion,
        listTipoReunion,
        saveTipoReunion,
        getTipoReunion,
        editTipoReunion

    }

}