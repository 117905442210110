import React, { useState } from 'react';
import moment from 'moment';
import { sum } from 'lodash';
import { SkyFilterHoras, TableHoursSimply, ChartTeamHours } from '../../components/Skytracking';
import { useSkytracking } from '../../hooks';

export function SkyCorteHoursPage() {
    const [listHours, setListHours] = useState([]);

    const [fechaChart, setFechaChart] = useState([]);
    const [programadaChart, setProgramadaChart] = useState([]);
    const [trabajadaChart, setTrabajadaChart] = useState([]);

    const [fechaLineChart, setLineChartFecha] = useState([]);
    const [programadaLineChart, setProgramadaLineChart] = useState([]);
    const [trabajadaLineChart, setTrabajadaLineChart] = useState([]);

    const [labelAux, setLabelAux] = useState([]);
    const [auxTrabajados, setAuxTrabajados] = useState([]);

    const [cardTrabajadas, setCardTrabajadas] = useState(0);
    const [cardProductivas, setCardProductivas] = useState(0);
    const [cardAux, setCardAux] = useState({
        Valor: 0,
        Pausa: ''
    });

    const { getMarcaSkyview, loadingSkytracking } = useSkytracking();

    const onResponseForm = async (formData) => {
        const fecha_inicio = moment(formData.RangoFechas[0]).format('YYYY-MM-DD');
        const fecha_final = moment(formData.RangoFechas[1]).format('YYYY-MM-DD');
        let list_empleados_seleccionados = [];

        for await (const empleado of formData.EmpleadosMultiple) {
            list_empleados_seleccionados.push(empleado.Badge);
        }

        const response_format_data = await getMarcaSkyview(fecha_inicio, fecha_final, list_empleados_seleccionados.join('|'));
        console.log("HERE")
        console.log(response_format_data)
        setListHours(response_format_data);

        let fecha_chart_format = ['Horas Trabajadas / Horas Programadas', 'Horas Productivas / Horas Disponible (Iddle)'];
        let aux_format = ['Break', 'Lunch', 'Personal', 'Coaching', '1x1', 'QA Feedback', 'Training', 'System Issues', 'Team Hunddle', 'Support', 'Meeting'];
        let programada_chart_format = [0, 0];
        let trabajada_chart_format = [0, 0];
        let aux_trabajadas = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        let list_fecha_horas = {};

        for (const marca of response_format_data) {
            //fecha_chart_format.push(`${marca.Badge} - ${marca.Nombre}`)
            //programada_chart_format.push(marca.HorasProgramadas)
            //trabajada_chart_format.push(marca.HorasTrabajadas)

            if (list_fecha_horas[marca.Fecha]?.Programadas == undefined) {
                if (!list_fecha_horas[marca.Fecha]) {
                    list_fecha_horas[marca.Fecha] = {};
                }

                list_fecha_horas[marca.Fecha]['Programadas'] = marca.TotalProgramadas;
                list_fecha_horas[marca.Fecha]['Trabajadas'] = marca.HorasPagar;
            } else {
                list_fecha_horas[marca.Fecha]['Programadas'] += marca.TotalProgramadas;
                list_fecha_horas[marca.Fecha]['Trabajadas'] += marca.HorasPagar;
            }

            programada_chart_format[0] += marca.HorasPagar;
            trabajada_chart_format[0] += marca.TotalProgramadas;
            programada_chart_format[1] += marca[2];
            trabajada_chart_format[1] += marca[121];

            aux_trabajadas[0] += marca[6];
            aux_trabajadas[1] += marca[31];
            aux_trabajadas[2] += marca[8];
            aux_trabajadas[3] += marca[16];
            aux_trabajadas[4] += marca[9];
            aux_trabajadas[5] += marca[10];
            aux_trabajadas[6] += marca[12];
            aux_trabajadas[7] += marca[14];
            aux_trabajadas[8] += marca[11];
            aux_trabajadas[9] += marca[17];
            aux_trabajadas[10] += marca[18];
        }

        setLabelAux(aux_format);
        setAuxTrabajados(aux_trabajadas);

        setFechaChart(fecha_chart_format);
        setProgramadaChart(programada_chart_format);
        setTrabajadaChart(trabajada_chart_format);

        setCardTrabajadas((programada_chart_format[0] / trabajada_chart_format[0]) * 100);
        setCardProductivas((trabajada_chart_format[1] / programada_chart_format[1]) * 100);

        let value_auxmayor = Math.max(...aux_trabajadas);
        let key_auxmayor = aux_trabajadas.indexOf(value_auxmayor);

        setCardAux({
            Valor: (value_auxmayor / sum(aux_trabajadas)) * 100,
            Pausa: aux_format[key_auxmayor]
        });

        const fechas = [];
        const horasProgramadas = [];
        const horasTrabajadas = [];

        // Iterar sobre las entradas del objeto
        for (const fecha in list_fecha_horas) {
            if (list_fecha_horas.hasOwnProperty(fecha)) {
                if (list_fecha_horas[fecha].Programadas > 0){

                    fechas.push(fecha);
                    horasProgramadas.push(list_fecha_horas[fecha].Programadas);
                    horasTrabajadas.push(list_fecha_horas[fecha].Trabajadas);

                }
            }
        }

        setLineChartFecha(fechas);
        setProgramadaLineChart(horasProgramadas);
        setTrabajadaLineChart(horasTrabajadas);

    };

    const camposFilter = {
        RangoFechas: true,
        EmpleadosMultiple: true
    };

    return (
        <>
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <h5>Filtros - Reporte por Equipo Asignado</h5>
                        <div className="grid my-3">
                            <div className="col-12">
                                <SkyFilterHoras responsefc={onResponseForm} Campos={camposFilter} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <h5>Tabla de Horas por Empleado</h5>

                        {loadingSkytracking ? (
                            <div className="grid">
                                <div className="col-12 my-3 text-center">
                                    <i className="pi pi-spinner pi-spin" style={{ fontSize: '5rem' }}></i>
                                </div>
                            </div>
                        ) : (
                            <div className="grid">
                                <div className="col-12 my-3">
                                    <TableHoursSimply Horas={listHours} />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className="grid my-3">
                <div className="col">
                    <div className="card overview-box blue">
                        <div className="overview-info">
                            <h6>Horas Trabajadas / Programadas</h6>
                            <h1>{cardTrabajadas.toFixed(2)} %</h1>
                        </div>
                        <i className="pi pi-users"></i>
                    </div>
                </div>
                <div className="col">
                    <div className="card overview-box darkgray">
                        <div className="overview-info">
                            <h6>Horas Productivas / Iddle</h6>
                            <h1>{cardProductivas.toFixed(2)} %</h1>
                        </div>
                        <i className="pi pi-th-large"></i>
                    </div>
                </div>
                <div className="col">
                    <div className="card overview-box orange">
                        <div className="overview-info">
                            <h6>Auxiliar Mas Utilizado: {cardAux.Pausa}</h6>
                            <h1>{cardAux.Valor.toFixed(2)} %</h1>
                        </div>
                        <i className="pi pi-cloud"></i>
                    </div>
                </div>
            </div>

            <div className="grid">
                <div className="md:col-6 col-12">
                    <div className="card">
                        <h5>Horas Trabajadas contra Programadas</h5>

                        <div className="grid my-5">
                            <div className="col-12">
                                <ChartTeamHours tipeChart="bar" arr_fechas={fechaChart} arr_trabajadas={trabajadaChart} arr_programadas={programadaChart} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="md:col-6 col-12">
                    <div className="card">
                        <h5>Auxiliares utilizados en el dia</h5>

                        <div className="grid my-5">
                            <div className="col-12">
                                <ChartTeamHours tipeChart="bar" arr_fechas={labelAux} arr_trabajadas={auxTrabajados} arr_programadas={[]} charPosition="y" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <h5>Grafica de Linea</h5>
                        <div className='grid'>
                            <div className='col-12 my-5'>

                                <ChartTeamHours className="my-5" tipeChart="line" arr_fechas={fechaLineChart} arr_trabajadas={trabajadaLineChart} arr_programadas={programadaLineChart} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
