import { useState } from "react"
import { addNotaApi, getNotaApi, updateNotaApi } from "../api/skymeet/nota"
import { useAuth } from "./useAuth"

export function useNotaMeet () {

    const [error, setError] = useState(false)
    const [loadingNota, setLoadingNota] = useState(false)
    const [listNota, setListNota] = useState(null)

    const {auth} = useAuth()

    const saveNota = async (FormData) => {
        try {
            setLoadingNota(true)

            const result = await addNotaApi(FormData, auth.token)           

            setLoadingNota(false)

            return result;
            
        } catch (error) {
            setLoadingNota(false)
            setError(error)
        }
    }

    const editNota = async (FormData, id) => {
        try {
            setLoadingNota(true)

            await updateNotaApi(FormData, id, auth.token)

            setLoadingNota(false)
            
        } catch (error) {
            setLoadingNota(false)
            setError(error)
        }
    }

    const getNota = async () => {
        try {
            setLoadingNota(true)
            const response_api = await getNotaApi(auth.token)
            setListNota(response_api)
            setLoadingNota(false)
            
        } catch (error) {
            setLoadingNota(false)
            setError(error)
            
        }
    }

    return {
        error,
        loadingNota,
        listNota,
        saveNota,
        getNota,
        editNota
    }

}