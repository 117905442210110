import { useState } from 'react';
import { size, filter, map, uniq } from 'lodash';
import moment from "moment";
import { getEmpleadoIdApi } from '../api/skyroster/empleado';
import { getEmpleadoByFilterApi, getDetalleEmpleadoApi, getSkyViewEmpleadoApi, getSkyViewEmpleadoByIdApi } from '../api/skyroster/detalleempleado';
import { getDetalleMarcaByMarcaFilterApi,  deleteDetalleMarcaApi, addDetalleMarcaApi, updateDetalleMarcaApi, getDetalleMarcaByFilterApi } from '../api/skytracking/detallemarca';
import { updateMarcaApi, getStaffTimeApi, getMarcasApi, getSkyViewMarcasApi, getHoursSkyviewApi } from '../api/skytracking/marca';
import { addMarcaRechazadaApi, getMarcaRechazadasApi, updateMarcaRechazadaApi } from '../api/skytracking/marcarechazada';
import { getCuentasApi } from '../api/skyroster/cuenta';
import { getLobsApi } from '../api/skyroster/lob';
import { useAuth } from './useAuth';
import { weekYearDate } from '../utilities/constants';
import { getHorarioApi } from '../api/skyroster/horario';
// import { useCommon, useMasterRoster } from '../../hooks';
import { useMasterRoster } from './useMasterRoster';



export function useSkytracking() {
    const [error, setError] = useState(false);
    const [loadingSkytracking, setLoadingSkytracking] = useState(false);
    const [horasSupervisor, setHorasSupervisor] = useState(null);
    const [datosConsultadosHoras, setDatosConsultadosHoras] = useState(0);
    const [horasPlanilla, setHorasPlanilla] = useState(null);
    const [marcasRechazadas, setMarcasRechazadas] = useState(null);
    const { getHorarioEmpleadoResponse, horarioEmpleado } = useMasterRoster()

    const { auth } = useAuth();

    const getMarcasDateCalc = async (FechaInicio, FechaFinal) => {
        try {
            setLoadingSkytracking(true)

            const response_marcas = await getMarcasApi(auth.token, "", FechaInicio, FechaFinal);

            setLoadingSkytracking(false)

            return response_marcas;
        } catch (error) {
            setLoadingSkytracking(false)
            setError(error)
        }
    }

    const getMarcaSkyview = async (FechaInicio, FechaFinal, Employee) => {
        try {
            setLoadingSkytracking(true)

            let response_view = []

            if (auth.me.is_staff){
                response_view = await getSkyViewEmpleadoApi(auth.token);
            }else{
                response_view = await getSkyViewEmpleadoApi(auth.token, auth.me.username);
            }

            const response_marcas = await getHoursSkyviewApi(auth.token, FechaInicio, FechaFinal, Employee);
            // console.log("Fechai: " + FechaInicio + " - Fechaf: "+ FechaFinal);
            // console.log(response_marcas);

            const list_pausas_col = [2,121,4,6,7,8,9,10,11,12,13,14,15,16,17,18,31]

            const pausas_trabajadasCrudo_dia = [2,121,18,6,7,9,8,16,10,17,15,14,11,13,12]
            const pausas_trabajadasCrudo_noche = [3,78,66,67,69,68,76,70,77,75,74,71,73,72,121]

            const pausas_devengadas_dia = [38,102,56,37,101,39,55,36,33,32,41,42,34,43]
            const pausas_devengadas_noche = [112,115,81,80,105,113,104,111,106,114,83,84,82,63]

            const pausas_online_dia = [18,6,7,9,8,16,10,17,15,14,11,13,12]
            const pausas_online_noche = [78,66,67,69,68,76,70,77,75,74,71,73,72]

            const pausas_offline_dia = [94,95,97,98,99,100,35,45,46,50,51,53,54,58,59,87,88,89,90,91,92,93,116,44,47,48,49,36,37,39,55,56,101,102,32,33,34,41,42,43]
            const pausas_offline_noche = [35,45,46,50,51,53,54,58,59,87,88,89,90,91,92,93,116,107,108,109,110,119,120,44,47,48,49,80,81,104,105,106,111,113,114,63,82,83,84]

            const pausas_excepcion_dia = [33,32,117,41,42,34,116,43]
            const pausas_excepcion_noche = [118,83,84,82,63]

            let list_format_table = []

            const diasSemana = {
                0: 'D', // Lunes
                1: 'L', // Martes
                2: 'M', // Miércoles
                3: 'X', // Jueves
                4: 'J', // Viernes
                5: 'V', // Sábado
                6: 'S'  // Domingo
              }

            for await (const marca of response_marcas){

                let list_format_table_detalle = {
                    "ID_Marca": marca.ID_Marca,
                    "ID_Empleado": marca.ID_Empleado,
                    "Badge": "Sin Badge",
                    "Nombre": "Sin Nombre",
                    "Cuenta": "Sin Cuenta",
                    "Fecha": marca.Fecha,
                    "HorasTrabajadas": 0,
                    "HorasPagar": 0,
                    "Lunch": 0,
                    "TiempoAjustado": 0,
                    "HorasProgramadas": 0,
                    "HorasProgramadas_noche": 0,
                    "TotalProgramadas": 0,
                    "HorasTrabajadasCrudo_dia": 0,
                    "HorasTrabajadsaCrudo_noche": 0,
                    "HorasDevengadas_dia": 0,
                    "HorasDevengadas_noche": 0,
                    "HorasOnline_dia": 0,
                    "HorasOnline_noche": 0,
                    "HorasOffline_dia": 0,
                    "HorasOffline_noche": 0,
                    "HorasExcepcion_dia": 0,
                    "HorasExcepcion_noche": 0
                }
                console.log(marca.Detalles);

                const horas_prog = filter(marca.Detalles, ["ID_TipoMarca", 1])

                if (size(horas_prog) > 0){
                    list_format_table_detalle["HorasProgramadas"] = horas_prog[0].Horas;
                }

                const horas_prog_noche = filter(marca.Detalles, ["ID_TipoMarca", 62])

                if (size(horas_prog_noche) > 0){
                    list_format_table_detalle["HorasProgramadas_noche"] = horas_prog_noche[0].Horas;
                }

                const Empleado_filter = filter(response_view, ["id", marca.ID_Empleado])

                if (size(Empleado_filter) > 0){
                    list_format_table_detalle["Badge"] = Empleado_filter[0].Badge;
                    list_format_table_detalle["Nombre"] = Empleado_filter[0].NombreEmpleado;
                    list_format_table_detalle["Cuenta"] = Empleado_filter[0].Cuenta;
                }else{
                    const empleado_new = await getSkyViewEmpleadoByIdApi(auth.token, marca.ID_Empleado);
                    
                    list_format_table_detalle["Badge"] = empleado_new[0].Badge;
                    list_format_table_detalle["Nombre"] = empleado_new[0].NombreEmpleado;
                    list_format_table_detalle["Cuenta"] = empleado_new[0].Cuenta;
                }
                

                // if (horarioProg != {}) {
                //     console.log(horarioProg[0].Almuerzo);
                // } else {
                //     console.log("xd");
                // }
                
                console.log("-----")
                // const lunch = 0;
                // const calc_lunch = 
                

                // console.log("Here " + marca.ID_Empleado + " " + fec.getUTCDay());                
                // const horarioProg = await getHorarioEmpleadoResponse(marca.ID_Empleado);
                // console.log(horarioProg);
                // console.log("Empleado: " + marca.ID_Empleado + " - Fecha marca: " + fec.getUTCDay() + " - Fecha Api: " + xd);
                // console.log(xd)

                for (const col of list_pausas_col){

                    const horas_detalle = filter(marca.Detalles, ["ID_TipoMarca", col])

                    if (size(horas_detalle) > 0){
                        list_format_table_detalle[col] = horas_detalle[0].Horas;
                        if (horas_detalle[0].Tipo == 2){
                            
                            list_format_table_detalle["HorasTrabajadas"] += horas_detalle[0].Horas;
                        }
                    }else{
                        list_format_table_detalle[col] = 0;
                    }
                }

                for await (const pausa of marca.Detalles){

                    switch (pausa.ID_TipoMarca) {
                        case 31:
                            list_format_table_detalle["Lunch"] += pausa.Horas;
                            break;
                        
                        case 60:
                            list_format_table_detalle["TiempoAjustado"] += pausa.Horas;
                            break;
                        
                        default:
                            break;
                    }

                    if(pausas_trabajadasCrudo_dia.includes(pausa.ID_TipoMarca)){

                        list_format_table_detalle["HorasTrabajadasCrudo_dia"] += pausa.Horas;
                    }

                    if(pausas_trabajadasCrudo_noche.includes(pausa.ID_TipoMarca)){

                        list_format_table_detalle["HorasTrabajadsaCrudo_noche"] += pausa.Horas;
                    }

                    if(pausas_devengadas_dia.includes(pausa.ID_TipoMarca)){

                        list_format_table_detalle["HorasDevengadas_dia"] += pausa.Horas;
                    }

                    if(pausas_devengadas_noche.includes(pausa.ID_TipoMarca)){

                        list_format_table_detalle["HorasDevengadas_noche"] += pausa.Horas;
                    }

                    if(pausas_online_dia.includes(pausa.ID_TipoMarca)){

                        list_format_table_detalle["HorasOnline_dia"] += pausa.Horas;
                    }

                    if(pausas_online_noche.includes(pausa.ID_TipoMarca)){

                        list_format_table_detalle["HorasOnline_noche"] += pausa.Horas;
                    }

                    if(pausas_offline_dia.includes(pausa.ID_TipoMarca)){

                        list_format_table_detalle["HorasOffline_dia"] += pausa.Horas;
                    }

                    if(pausas_offline_noche.includes(pausa.ID_TipoMarca)){

                        list_format_table_detalle["HorasOffline_noche"] += pausa.Horas;
                    }

                    if(pausas_excepcion_dia.includes(pausa.ID_TipoMarca)){

                        list_format_table_detalle["HorasExcepcion_dia"] += pausa.Horas;
                    }

                    if(pausas_excepcion_noche.includes(pausa.ID_TipoMarca)){

                        list_format_table_detalle["HorasExcepcion_noche"] += pausa.Horas;
                    }


                }               
                const fec = new Date (marca.Fecha)
                console.log(marca.Fecha)
                // console.log(marca);
                const horarioProg = await getHorarioEmpleadoResponse(marca.ID_Empleado, diasSemana[fec.getUTCDay()]);
                let calcLunch = 0;
                const xReal = parseFloat(list_format_table_detalle["Lunch"]) 
                if (horarioProg.length > 0 && xReal > 0) {
                    console.log("Entró")
                    const y = horarioProg[0].Almuerzo
                    calcLunch =  xReal <= y? y - xReal : xReal - y
                    // console.log(calcLunch);
                    // console.log("Szs: " + xReal + " --- " + y);
                } else {
                    if (xReal == 0 ) {
                        calcLunch = 0;
                    } else {
                        calcLunch =  xReal <= 1? 1 - xReal : xReal - 1;
                    }
                    
                    // console.log("Nolas");
                }

                list_format_table_detalle["HorasTrabajadasCrudo_dia"] = (list_format_table_detalle["HorasTrabajadasCrudo_dia"] > list_format_table_detalle["HorasProgramadas"]) ? list_format_table_detalle["HorasProgramadas"] : list_format_table_detalle["HorasTrabajadasCrudo_dia"];
                list_format_table_detalle["HorasTrabajadsaCrudo_noche"] = (list_format_table_detalle["HorasTrabajadsaCrudo_noche"] > list_format_table_detalle["HorasProgramadas_noche"]) ? list_format_table_detalle["HorasProgramadas_noche"] : list_format_table_detalle["HorasTrabajadsaCrudo_noche"];

                let cal_horas_pagar = list_format_table_detalle["HorasTrabajadasCrudo_dia"] + list_format_table_detalle["HorasTrabajadsaCrudo_noche"] + list_format_table_detalle["HorasDevengadas_dia"] + list_format_table_detalle["HorasDevengadas_noche"]

                list_format_table_detalle["HorasPagar"] = cal_horas_pagar;
                
                let cal_horas_prog = parseFloat(list_format_table_detalle["HorasProgramadas"]) + parseFloat(list_format_table_detalle["HorasProgramadas_noche"]);

                list_format_table_detalle["TotalProgramadas"] = cal_horas_prog;

                list_format_table_detalle["HorasPagar"] = (list_format_table_detalle["HorasPagar"] > cal_horas_prog) ? cal_horas_prog : list_format_table_detalle["HorasPagar"];
              
                list_format_table_detalle["HorasPagar"] = list_format_table_detalle["HorasPagar"] - calcLunch
              
                list_format_table_detalle["HorasTrabajadas"] = (list_format_table_detalle["HorasTrabajadas"] > list_format_table_detalle["HorasProgramadas"]) ? list_format_table_detalle["HorasProgramadas"] : list_format_table_detalle["HorasTrabajadas"];

                list_format_table.push(list_format_table_detalle)
            }

            setLoadingSkytracking(false)
            console.log("Here 2")
            console.log(list_format_table)
            return list_format_table;
        } catch (error) {
            setLoadingSkytracking(false)
            setError(error)
            console.log("Here 3")
            console.log(error)
        }
    }

    const getPersonalHours = async (Fecha) => {
        try {
            setLoadingSkytracking(true);

            const calc_segundos = (1800 * 5) / 591;
            setDatosConsultadosHoras(calc_segundos);

            const response_marcas = await getDetalleMarcaByMarcaFilterApi(auth.token, auth.me.username, Fecha);
            let final_arr_marcas = [];

            let marca_id = 0;
            let arr_temp = [];
            let contador = 0;

            for await (const marca of response_marcas) {
                if (marca_id != marca.Marca) {
                    if (size(arr_temp) > 0) {
                        arr_temp[1] = parseFloat(arr_temp[1]) + parseFloat(arr_temp[62]);
                        arr_temp[30] = parseFloat(arr_temp[30]) + parseFloat(arr_temp[65]);
                        arr_temp[60] = parseFloat(arr_temp[60]) + parseFloat(arr_temp[96]);
                        if (arr_temp['HorasTrabajadas'] > 0){

                            arr_temp[57] = parseFloat(arr_temp[57]) + parseFloat(arr_temp[64]);
                            arr_temp["NoCallNoShow"] = 0;
                            
                        }else{
                            arr_temp["NoCallNoShow"] = parseFloat(arr_temp[57]) + parseFloat(arr_temp[64]);
                            arr_temp[57] = 0;
                        }

                        arr_temp['HorasTrabajadas'] = arr_temp['HorasTrabajadas'] > arr_temp[1] ? arr_temp[1] : arr_temp['HorasTrabajadas'];
                        arr_temp['HorasPagar'] = parseFloat(arr_temp['HorasAdicionales']) + parseFloat(arr_temp['HorasExtra']) + parseFloat(arr_temp['HorasTrabajadas']) + parseFloat(arr_temp['HorasPagadasPorLey']);

                        final_arr_marcas[contador] = arr_temp;

                        arr_temp = [];

                        contador += 1;
                    }

                    marca_id = marca.Marca;

                    const nombre_response = await getDetalleEmpleadoApi(auth.token, 2, marca.marca_data.Empleado);
                    const badge_response = await getEmpleadoIdApi(auth.token, marca.marca_data.Empleado);

                    arr_temp['idMarca'] = marca.Marca;
                    arr_temp['EstadoMarca'] = marca.marca_data.Confirmacion;
                    arr_temp['Empleado'] = marca.marca_data.Empleado;
                    arr_temp['idEmpleado'] = marca.marca_data.Empleado;
                    arr_temp['Badge'] = badge_response[0].Badge;
                    arr_temp['Nombre'] = nombre_response[0].Valor;
                    arr_temp['Fecha'] = marca.marca_data.Fecha;

                    arr_temp['HorasDevengadas_online'] = 0;
                    arr_temp['HorasPagadasPorLey'] = 0;
                    arr_temp['HorasTrabajadas'] = 0;
                    arr_temp['HorasDevengadas_offline'] = 0;
                    arr_temp['HorasAdicionales'] = 0;
                    arr_temp['HorasExtra'] = 0;
                    arr_temp['HorasPagar'] = 0;

                    arr_temp[marca.TipoMarca] = marca.Horas;
                } else {
                    arr_temp[marca.TipoMarca] = marca.Horas;
                }

                switch (marca.tipoMarca_data.Tipo) {
                    case 2:
                        arr_temp['HorasDevengadas_online'] += parseFloat(marca.Horas);
                        //arr_temp['HorasPagar'] += parseFloat(marca.Horas);
                        arr_temp['HorasTrabajadas'] += parseFloat(marca.Horas);
                        break;
                    case 3:
                        arr_temp['HorasExtra'] += parseFloat(marca.Horas);
                        //arr_temp['HorasPagar'] += parseFloat(marca.Horas);
                        break;
                    case 4:
                        arr_temp['HorasAdicionales'] += parseFloat(marca.Horas);
                        //arr_temp['HorasPagar'] += parseFloat(marca.Horas);
                        break;
                    case 5:
                        arr_temp['HorasDevengadas_offline'] += parseFloat(marca.Horas);
                        //arr_temp['HorasPagar'] += parseFloat(marca.Horas);
                        arr_temp['HorasTrabajadas'] += parseFloat(marca.Horas);
                        break;
                    case 8:
                        arr_temp['HorasPagadasPorLey'] += parseFloat(marca.Horas);
                    case 9:
                        arr_temp['HorasTrabajadas'] += parseFloat(marca.Horas);
                        arr_temp['HorasDevengadas_offline'] += parseFloat(marca.Horas);
                    default:
                        break;
                }
            }

            arr_temp[1] = parseFloat(arr_temp[1]) + parseFloat(arr_temp[62]);
            arr_temp[30] = parseFloat(arr_temp[30]) + parseFloat(arr_temp[65]);
            arr_temp[60] = parseFloat(arr_temp[60]) + parseFloat(arr_temp[96]);
            if (arr_temp['HorasTrabajadas'] > 0){

                arr_temp[57] = parseFloat(arr_temp[57]) + parseFloat(arr_temp[64]);
                arr_temp["NoCallNoShow"] = 0;
                
            }else{
                arr_temp["NoCallNoShow"] = parseFloat(arr_temp[57]) + parseFloat(arr_temp[64]);
                arr_temp[57] = 0;
            }

            arr_temp['HorasTrabajadas'] = arr_temp['HorasTrabajadas'] > arr_temp[1] ? arr_temp[1] : arr_temp['HorasTrabajadas'];
            arr_temp['HorasPagar'] = parseFloat(arr_temp['HorasAdicionales']) + parseFloat(arr_temp['HorasExtra']) + parseFloat(arr_temp['HorasTrabajadas']) + parseFloat(arr_temp['HorasPagadasPorLey']);

            final_arr_marcas[contador] = arr_temp;

            setLoadingSkytracking(false);

            return final_arr_marcas;
        } catch (error) {
            setLoadingSkytracking(false);
            setError(error);
        }
    };

    const getHorasSup = async (Cuenta, Lob, Fecha, numDias, cont) => {
        try {
            setLoadingSkytracking(true);
            const calc_segundos = ((numDias - cont) * 1800 * 10) / 591;
            setDatosConsultadosHoras(calc_segundos);

            let response_empleado = [];
            let employee_arr = [];

            if (Lob == '') {
                response_empleado = await getEmpleadoByFilterApi(auth.token, Cuenta, 3, moment().week());
            } else {
                response_empleado = await getEmpleadoByFilterApi(auth.token, Lob, 5, moment().week());
            }

            if (size(response_empleado) > 0) {
                for await (const empleado of response_empleado) {
                    employee_arr.push(empleado.empleado_data.Badge);
                }
            } else {
                throw new Error('No Existe Empleados para la cuenta');
            }

            let final_arr_marcas = [];
            
            employee_arr = uniq(employee_arr)
            
            const response_marcas = await getDetalleMarcaByMarcaFilterApi(auth.token, employee_arr.join('%2C'), Fecha);
            let marca_id = 0;
            let arr_temp = [];
            let contador = 0;

            for await (const marca of response_marcas) {
                if (marca_id != marca.Marca) {
                    if (size(arr_temp) > 0) {
                        arr_temp[1] = parseFloat(arr_temp[1]) + parseFloat(arr_temp[62]);
                        arr_temp[30] = parseFloat(arr_temp[30]) + parseFloat(arr_temp[65]);
                        arr_temp[60] = parseFloat(arr_temp[60]) + parseFloat(arr_temp[96]);
                        if (arr_temp['HorasTrabajadas'] > 0){

                            arr_temp[57] = parseFloat(arr_temp[57]) + parseFloat(arr_temp[64]);
                            arr_temp["NoCallNoShow"] = 0;
                            
                        }else{
                            arr_temp["NoCallNoShow"] = parseFloat(arr_temp[57]) + parseFloat(arr_temp[64]);
                            arr_temp[57] = 0;
                        }

                        arr_temp['HorasTrabajadas'] = arr_temp['HorasTrabajadas'] > arr_temp[1] ? arr_temp[1] : arr_temp['HorasTrabajadas'];
                        arr_temp['HorasPagar'] = parseFloat(arr_temp['HorasAdicionales']) + parseFloat(arr_temp['HorasExtra']) + parseFloat(arr_temp['HorasTrabajadas']) + parseFloat(arr_temp['HorasPagadasPorLey']);

                        final_arr_marcas[contador] = arr_temp;

                        arr_temp = [];

                        contador += 1;
                    }

                    marca_id = marca.Marca;

                    const nombre_response = await getDetalleEmpleadoApi(auth.token, 2, marca.marca_data.Empleado);
                    const badge_response = await getEmpleadoIdApi(auth.token, marca.marca_data.Empleado);

                    arr_temp['idMarca'] = marca.Marca;
                    arr_temp['EstadoMarca'] = marca.marca_data.Confirmacion;
                    arr_temp['ValidacionMarca'] = marca.marca_data.Validacion;
                    arr_temp['Empleado'] = marca.marca_data.Empleado;
                    arr_temp['idEmpleado'] = marca.marca_data.Empleado;
                    arr_temp['Badge'] = badge_response[0].Badge;
                    arr_temp['Nombre'] = nombre_response[0].Valor;
                    arr_temp['Fecha'] = marca.marca_data.Fecha;

                    arr_temp['HorasDevengadas_online'] = 0;
                    arr_temp['HorasPagadasPorLey'] = 0;
                    arr_temp['HorasTrabajadas'] = 0;
                    arr_temp['HorasDevengadas_offline'] = 0;
                    arr_temp['HorasAdicionales'] = 0;
                    arr_temp['HorasExtra'] = 0;
                    arr_temp['HorasPagar'] = 0;

                    arr_temp[marca.TipoMarca] = marca.Horas;
                } else {
                    arr_temp[marca.TipoMarca] = marca.Horas;
                }

                switch (marca.tipoMarca_data.Tipo) {
                    case 2:
                        arr_temp['HorasDevengadas_online'] += parseFloat(marca.Horas);
                        //arr_temp['HorasPagar'] += parseFloat(marca.Horas);
                        arr_temp['HorasTrabajadas'] += parseFloat(marca.Horas);
                        break;
                    case 3:
                        arr_temp['HorasExtra'] += parseFloat(marca.Horas);
                        //arr_temp['HorasPagar'] += parseFloat(marca.Horas);
                        break;
                    case 4:
                        arr_temp['HorasAdicionales'] += parseFloat(marca.Horas);
                        //arr_temp['HorasPagar'] += parseFloat(marca.Horas);
                        break;
                    case 5:
                        arr_temp['HorasDevengadas_offline'] += parseFloat(marca.Horas);
                        //arr_temp['HorasPagar'] += parseFloat(marca.Horas);
                        arr_temp['HorasTrabajadas'] += parseFloat(marca.Horas);
                        break;
                    case 8:
                        arr_temp['HorasPagadasPorLey'] += parseFloat(marca.Horas);
                    case 9:
                        arr_temp['HorasTrabajadas'] += parseFloat(marca.Horas);
                        arr_temp['HorasDevengadas_offline'] += parseFloat(marca.Horas);
                    default:
                        break;
                }
            }

            arr_temp[1] = parseFloat(arr_temp[1]) + parseFloat(arr_temp[62]);
            arr_temp[30] = parseFloat(arr_temp[30]) + parseFloat(arr_temp[65]);
            arr_temp[60] = parseFloat(arr_temp[60]) + parseFloat(arr_temp[96]);
            if (arr_temp['HorasTrabajadas'] > 0){

                arr_temp[57] = parseFloat(arr_temp[57]) + parseFloat(arr_temp[64]);
                arr_temp["NoCallNoShow"] = 0;
                
            }else{
                arr_temp["NoCallNoShow"] = parseFloat(arr_temp[57]) + parseFloat(arr_temp[64]);
                arr_temp[57] = 0;
            }

            arr_temp['HorasTrabajadas'] = arr_temp['HorasTrabajadas'] > arr_temp[1] ? arr_temp[1] : arr_temp['HorasTrabajadas'];
            arr_temp['HorasPagar'] = parseFloat(arr_temp['HorasAdicionales']) + parseFloat(arr_temp['HorasExtra']) + parseFloat(arr_temp['HorasTrabajadas']) + parseFloat(arr_temp['HorasPagadasPorLey']);

            final_arr_marcas[contador] = arr_temp;

            setLoadingSkytracking(false);

            return final_arr_marcas;
        } catch (error) {
            setLoadingSkytracking(false);
            setError(error);
        }
    };

    const onAprobarHorasPersonales = async (idMarca, estado) => {
        try {
            setLoadingSkytracking(true);

            const ahora = new Date();

            await updateMarcaApi(
                {
                    Confirmacion: estado,
                    TiempoConfirmacion: `${ahora.getFullYear()}-${ahora.getMonth() + 1 < 10 ? `0${ahora.getMonth() + 1}` : ahora.getMonth() + 1}-${ahora.getDate()}T${ahora.getHours()}:${ahora.getMinutes()}`
                },
                idMarca,
                auth.token
            );

            setLoadingSkytracking(false);
        } catch (error) {
            setLoadingSkytracking(false);
            setError(error);
        }
    };

    const onChangeStateMarcaRechazada = async (idMarca, estado) => {
        try {
            setLoadingSkytracking(true);

            const response_marcaRechazada = await getMarcaRechazadasApi(auth.token, idMarca);

            if (size(response_marcaRechazada) > 0) {
                await updateMarcaRechazadaApi(
                    {
                        Estado: estado
                    },
                    response_marcaRechazada[0].id,
                    auth.token
                );
            }

            setLoadingSkytracking(false);
        } catch (error) {
            setLoadingSkytracking(false);
            setError(error);
        }
    };

    const onRechazarHorasPersonales = async (motivo, idMarca) => {
        try {
            setLoadingSkytracking(true);

            await addMarcaRechazadaApi(
                {
                    Marca: idMarca,
                    Estado: 0,
                    NotaRechazada: motivo
                },
                auth.token
            );

            setLoadingSkytracking(false);
        } catch (error) {
            setLoadingSkytracking(false);
            setError(error);
        }
    };

    const onValidarHorasSupervisor = async (idMarca) => {
        try {
            setLoadingSkytracking(true);

            const ahora = new Date();

            await updateMarcaApi(
                {
                    Validacion: auth.me.username,
                    TiempoValidacion: `${ahora.getFullYear()}-${ahora.getMonth() + 1 < 10 ? `0${ahora.getMonth() + 1}` : ahora.getMonth() + 1}-${ahora.getDate()}T${ahora.getHours()}:${ahora.getMinutes()}`
                },
                idMarca,
                auth.token
            );

            setLoadingSkytracking(false);
        } catch (error) {
            setLoadingSkytracking(false);
            setError(error);
        }
    };

    const getMarcasEditar = async (Badge, Fecha) => {
        try {
            setLoadingSkytracking(true);
            const response = await getDetalleMarcaByMarcaFilterApi(auth.token, Badge, Fecha);
            setLoadingSkytracking(false);

            return response;
        } catch (error) {
            setLoadingSkytracking(false);
            setError(error);
        }
    };

    const getMarcasEditarEspec = async (Badge, Fecha, Pausa) => {
        try {
            setLoadingSkytracking(true);
            const response = await getDetalleMarcaByMarcaFilterApi(auth.token, Badge, Fecha, Pausa);
            setLoadingSkytracking(false);

            return response;
        } catch (error) {
            setLoadingSkytracking(false);
            setError(error);
        }
    };

    const deleteMarcasEditar = async (idDetalleMarca) => {
        try {
            setLoadingSkytracking(true);
            await deleteDetalleMarcaApi(idDetalleMarca, auth.token);
            setLoadingSkytracking(false);
        } catch (error) {
            setLoadingSkytracking(false);
            setError(error);
        }
    };

    const updateMarcasEditar = async (horas, idDetalleMarca) => {
        try {
            setLoadingSkytracking(true);
            await updateDetalleMarcaApi(
                {
                    Horas: horas
                },
                idDetalleMarca,
                auth.token
            );
            setLoadingSkytracking(false);
        } catch (error) {
            setLoadingSkytracking(false);
            setError(error);
        }
    };

    const addDetalleMarcasEditar = async (Marca, Pausa, Horas) => {
        try {
            setLoadingSkytracking(true);
            await addDetalleMarcaApi(
                {
                    Marca: Marca,
                    TipoMarca: Pausa,
                    Horas: Horas
                },
                auth.token
            );
            setLoadingSkytracking(false);
        } catch (error) {
            setLoadingSkytracking(false);
            setError(error);
        }
    };

    const getMarcasRechazadas = async () => {
        try {
            setLoadingSkytracking(true);
            let response = {}
            if(auth.me.is_staff){

                response = await getMarcaRechazadasApi(auth.token, '', 0);
            }else{

                let badges_asignados = []

                const response_empleado_sup = await getEmpleadoByFilterApi(auth.token, auth.me.username, 10, weekYearDate());
                const response_empleado_account = await getEmpleadoByFilterApi(auth.token, auth.me.username, 11, weekYearDate());
                const response_empleado_director = await getEmpleadoByFilterApi(auth.token, auth.me.username, 12, weekYearDate());

                for await (const empleado of response_empleado_sup) {
                    badges_asignados.push(empleado.empleado_data.Badge);
                }
                for await (const empleado of response_empleado_account) {
                    badges_asignados.push(empleado.empleado_data.Badge);
                }
                for await (const empleado of response_empleado_director) {
                    badges_asignados.push(empleado.empleado_data.Badge);
                }

                response = await getMarcaRechazadasApi(auth.token, '', 0, badges_asignados.join("%2c"));
            }
            let response_result = [];

            for await (const marca of response) {
                const nombre_response = await getDetalleEmpleadoApi(auth.token, 2, marca.marca_data.Empleado);
                const badge_response = await getEmpleadoIdApi(auth.token, marca.marca_data.Empleado);

                response_result.push({
                    Empleado: `${badge_response[0].Badge} - ${nombre_response[0].Valor}`,
                    Fecha: marca.marca_data.Fecha,
                    Motivo: marca.NotaRechazada
                });
            }

            setMarcasRechazadas(response_result);
            setLoadingSkytracking(false);
        } catch (error) {
            setLoadingSkytracking(false);
            setError(error);
        }
    };

    const getPlanillaHoras = async (FechaInicio, FechaFinal, numDias, cuenta) => {
        try {
            setLoadingSkytracking(true);
            const calc_segundos = (numDias * 1800 * 61) / 591;
            setDatosConsultadosHoras(calc_segundos);

            let response_marcas = [];

            if (cuenta == '') {
                response_marcas = await getDetalleMarcaByMarcaFilterApi(auth.token, '', '', '', FechaInicio, FechaFinal);
            } else {
                const response_empleado = await getEmpleadoByFilterApi(auth.token, cuenta, 3);
                let employee_arr = [];

                if (size(response_empleado) > 0) {
                    for await (const empleado of response_empleado) {
                        employee_arr.push(empleado.empleado_data.Badge);
                    }

                    response_marcas = await getDetalleMarcaByMarcaFilterApi(auth.token, employee_arr.join('%2C'), '', '', FechaInicio, FechaFinal);
                }
            }

            const response_cuentas = await getCuentasApi(auth.token, 1);
            const response_lob = await getLobsApi(auth.token, true);
            /* const response_empleados_nombres = await getEmpleadoByFilterApi(auth.token, '', 2); */
            let marcasIndexadas = [];
            let listMarcas = [];

            for await (const marca of response_marcas) {
                let search_marca = marcasIndexadas.includes(marca.Marca);

                if (!search_marca) {
                    marcasIndexadas.push(marca.Marca);

                    const filter_marca = filter(response_marcas, ['Marca', marca.Marca]);

                    if (size(filter_marca) > 0) {
                        let listMarcasDetalle = [];

                        listMarcasDetalle['HorasTrabajadas'] = 0;
                        listMarcasDetalle['HorasExtra'] = 0;
                        listMarcasDetalle['HorasAdicionales'] = 0;
                        listMarcasDetalle['FechaMarca'] = filter_marca[0].marca_data.Fecha;

                        /* const filter_name_badge = filter(response_empleados_nombres, ['Empleado', filter_marca[0].marca_data.Empleado]); */

                        const filter_name_badge = await getEmpleadoByFilterApi(auth.token, '', 2, '', '', filter_marca[0].marca_data.Empleado);
                        const filter_name_account = await getEmpleadoByFilterApi(auth.token, '', 3, '', '', filter_marca[0].marca_data.Empleado);
                        let result_filter_account = [];

                        if (size(filter_name_badge) > 0 && size(filter_name_account) > 0) {
                            result_filter_account = filter(response_cuentas, ['id', parseInt(filter_name_account[0].Valor)]);

                            if (size(result_filter_account) > 0) {
                                listMarcasDetalle['Cuenta'] = result_filter_account[0].Nombre;
                            } else {
                                listMarcasDetalle['Cuenta'] = 'Cuenta no Ingresada';
                            }

                            listMarcasDetalle['Badge'] = filter_name_badge[0].empleado_data.Badge;
                            listMarcasDetalle['Nombre'] = filter_name_badge[0].Valor;
                        } else {
                            listMarcasDetalle['Badge'] = 'Badge No Encontrado';
                            listMarcasDetalle['Nombre'] = 'Nombre no Encontrado';
                            listMarcasDetalle['Cuenta'] = 'Cuenta no Encontrada';
                        }

                        map(filter_marca, (detalle_marca, index) => {
                            listMarcasDetalle[detalle_marca.TipoMarca] = parseFloat(detalle_marca.Horas);

                            switch (detalle_marca.tipoMarca_data.Tipo) {
                                case 2:
                                    listMarcasDetalle['HorasTrabajadas'] += parseFloat(detalle_marca.Horas);
                                    break;
                                case 5:
                                    listMarcasDetalle['HorasTrabajadas'] += parseFloat(detalle_marca.Horas);
                                    break;
                                case 3:
                                    listMarcasDetalle['HorasExtra'] += parseFloat(detalle_marca.Horas);
                                    break;
                                case 4:
                                    listMarcasDetalle['HorasAdicionales'] += parseFloat(detalle_marca.Horas);
                                    break;
                            }
                        });

                        listMarcas.push(listMarcasDetalle);
                    }
                }
            }

            setHorasPlanilla(listMarcas);

            setLoadingSkytracking(false);
        } catch (error) {
            console.log(error);
            setLoadingSkytracking(false);
            setError(error);
        }
    };

    const getStaffTime = async (FechaInicio, FechaFinal) => {
        try {
            setLoadingSkytracking(true)
            const marcas_stafftime_response = await getStaffTimeApi(auth.token, FechaInicio, FechaFinal)

            setHorasPlanilla(marcas_stafftime_response)
            setLoadingSkytracking(false)
        } catch (error) {
            setLoadingSkytracking(false)
            setError(error)
        }
    }

    const getSkyViewMarcas = async (Fecha, Marca, Lob) => {
        try {
            setLoadingSkytracking(true)
            const response_marcas = await getSkyViewMarcasApi(auth.token, Fecha, Marca, Lob)
            setLoadingSkytracking(false)

            return response_marcas
        } catch (error) {
            setLoadingSkytracking(false)
            setError(error)
        }
    }

    return {
        error,
        loadingSkytracking,
        horasSupervisor,
        marcasRechazadas,
        horasPlanilla,
        datosConsultadosHoras,
        setDatosConsultadosHoras,
        getHorasSup,
        getPersonalHours,
        onAprobarHorasPersonales,
        onRechazarHorasPersonales,
        onValidarHorasSupervisor,
        onChangeStateMarcaRechazada,
        getMarcasEditar,
        deleteMarcasEditar,
        updateMarcasEditar,
        addDetalleMarcasEditar,
        getMarcasRechazadas,
        getPlanillaHoras,
        getStaffTime,
        getMarcasDateCalc,
        getSkyViewMarcas,
        getMarcasEditarEspec,
        getMarcaSkyview
    };
}
