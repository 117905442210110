import { useState } from 'react';
import { getCuentasApi } from '../api/skyroster/cuenta';
import { getLobsApi } from '../api/skyroster/lob';
import { getSubLobsApi } from '../api/skyroster/sublob';
import { getAsignacionCuentasApi } from '../api/skyroster/asignacioncuenta';
/* import { getEmpleadoByFilterApi } from '../api/skyroster/detalleempleado';
import { weekYearDate } from '../utilities/constants'; */
import { useAuth } from './useAuth';

export function useFilterRoster() {
    const [loadingFilterRoster, setLoadingFilterRoster] = useState(true);
    const [error, setError] = useState(false);
    const [cuentas, setCuentas] = useState(null);
    const [lobs, setLobs] = useState(null);
    const [subLobs, setSubLobs] = useState(null);
    const [supervisores, setSupervisores] = useState(null);
    const [estadosRosterUpdate, setEstadosRosterUpdate] = useState(null);

    const { auth } = useAuth();

    const getCuentas = async () => {
        try {
            setLoadingFilterRoster(true);
            const result = [];

            if (auth.me.is_staff){

                const response = await getCuentasApi(auth.token, 1);
                for await (const cuenta of response) {
                    result.push({
                        name: cuenta.Nombre,
                        value: cuenta.id
                    });
                }

            }else{
                const response = await getAsignacionCuentasApi(auth.token, auth.me.id);
                for await (const cuenta of response) {
                    result.push({
                        name: cuenta.cuenta_data.Nombre,
                        value: cuenta.cuenta_data.id
                    });
                }
            }

            

            setLoadingFilterRoster(false);
            setCuentas(result);
        } catch (error) {
            setLoadingFilterRoster(false);
            setError(error);
        }
    };

    const getCuentas_all = async () => {
        try {
            setLoadingFilterRoster(true);
            const result = [];

            const response = await getCuentasApi(auth.token, 1);
            for await (const cuenta of response) {
                result.push({
                    name: cuenta.Nombre,
                    value: cuenta.id
                });
            }
            
            setLoadingFilterRoster(false);
            setCuentas(result);
        } catch (error) {
            setLoadingFilterRoster(false);
            setError(error);
        }
    };

    const getDependientes = async (cuenta_id) => {
        try {
            setLoadingFilterRoster(true);

            const response_lob = await getLobsApi(auth.token, 1, cuenta_id);
            const result_lob = [];

            /* const response_sups = await getEmpleadoByFilterApi(auth.token, cuenta_id, 3, weekYearDate());
            const result_sups = []; */

            for await (const lob of response_lob) {
                result_lob.push({
                    name: lob.Lob,
                    value: lob.id
                });
            }

            /* for await (const supervisor of response_sups) {
                const response_plaza = await getEmpleadoByFilterApi(auth.token, 'SUP', 6, weekYearDate(), supervisor.empleado_data.Badge);

                if (response_plaza > 0) {
                    const response_name = await getEmpleadoByFilterApi(auth.token, '', 2, weekYearDate(), supervisor.empleado_data.Badge);

                    result_sups.push({
                        name: `${supervisor.empleado_data.Badge} - ${response_name[0].Valor}`,
                        value: supervisor.empleado_data.Badge
                    });
                }
            } */

            setLobs(result_lob);
            /* setSupervisores(result_sups); */

            setLoadingFilterRoster(false);
        } catch (error) {
            setLoadingFilterRoster(false);
            setError(error);
        }
    };

    const getLob2 = async (lob_id) => {
        try {
            setLoadingFilterRoster(true);

            const response_lob = await getSubLobsApi(auth.token, 1, lob_id);
            const result_lob = [];

            for await (const lob of response_lob) {
                result_lob.push({
                    name: lob.SubLob,
                    value: lob.id
                });
            }

            setSubLobs(result_lob);

            setLoadingFilterRoster(false);
        } catch (error) {
            setLoadingFilterRoster(false);
            setError(error);
        }
    };

    return {
        loadingFilterRoster,
        error,
        cuentas,
        lobs,
        supervisores,
        estadosRosterUpdate,
        subLobs,
        getCuentas,
        getDependientes,
        getLob2,
        getCuentas_all
    };
}
