import React, { useEffect, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTipoExcepcionMeet } from '../../hooks';
import { TableTipoExcepcion } from '../../components/Skymeet/TableTipoExcepcion';

export function TipoExcepcionPage() {
    const [refresh, setRefresh] = useState(false);
    const [idTipoExcepcion, setIdTipoExcepcion] = useState(0);

    const toast = useRef();

    const { saveTipoExcepcion, getTipoExcepcion, listTipoExcepcion, loadingTipoExcepcion, editTipoExcepcion } = useTipoExcepcionMeet();

    const formik = useFormik({
        initialValues: initialValues(),
        validationSchema: Yup.object(validationSchema()),
        onSubmit: async (formValue) => {

            if (idTipoExcepcion > 0){
                await editTipoExcepcion(formValue, idTipoExcepcion);
            }else{

                await saveTipoExcepcion(formValue);

            }

            toast.current.show({ severity: 'success', summary: 'Exito', detail: 'Tipo de excepción agregada correctamente' });

            onCleanField();

            onRefresh();
        },
        validate: () => {
            if (formik.errors.Nombre) {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debes completar todos los campos' });
            }
        },
        validateOnChange: false
    });

    const onRefresh = () => setRefresh((prev) => !prev);

    useEffect(() => {
        document.title = "Tipos de Excepciones";
        (async () => {
            await getTipoExcepcion();
        })();
    }, [refresh]);

    const onCleanField = () => {
        formik.setFieldValue('Nombre', '');
        setIdTipoExcepcion(0);
    };

    const onEditTipoExcepcion = (rowData) => {
        formik.setFieldValue('Nombre', rowData.Nombre);
        setIdTipoExcepcion(rowData.id);
    };

    return (
        <>
            <Toast ref={toast} />
            <div className="grid">
                <div className="col-12">
                    <div className="card my-3">
                        <h5>Tipos de excepciones - Administrador</h5>
                        <p>Agrega los tipos de excepciones que pueden ser registradas</p>
                        <p>Antes de crear un tipo de excepción, recuerda validar los ya existentes.</p>
                        <form onSubmit={formik.handleSubmit}>
                            <div className="grid my-5">
                                <div className="col-4">
                                    <span className="p-float-label">
                                        <InputText id="Nombre" name="Nombre" value={formik.values.Nombre} onChange={formik.handleChange} className="w-full" />
                                        <label htmlFor="Nombre">
                                            Nombre del tipo de excepción <b style={{ color: 'red' }}>*</b>
                                        </label>
                                    </span>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="col-12 text-right">
                                    
                                    {idTipoExcepcion > 0 ? (
                                        <>
                                            <Button label='Editar tipo de excepción' severity="warning" disabled>                                            
                                                &nbsp;
                                                <i className="pi pi-pencil"></i>
                                            </Button>
                                            &nbsp;
                                            <Button label="" style={{minHeight: '100%' }} severity="danger" type="button" onClick={onCleanField}>
                                                <i className="pi pi-times"></i>
                                            </Button>
                                        </>
                                    ) : (
                                        <Button label='Nuevo tipo de excepción' severity="success" disabled>                                            
                                            &nbsp;
                                            <i className="pi pi-check"></i>
                                        </Button>
                                    )}
                                    &nbsp;
                                    <Button type="submit" severity="primary">
                                        Guardar tipo excepción
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            {loadingTipoExcepcion ? (
                <div className="grid">
                    <div className="col-12 text-center">
                        <i className="pi pi-spinner pi-spin" style={{ fontSize: '5rem' }}></i>
                    </div>
                </div>
            ) : (
                <TableTipoExcepcion listTipoExcepcionShow={listTipoExcepcion} editPadre={onEditTipoExcepcion} />
            )}
        </>
    );
}

function initialValues() {
    return {
        Nombre: '',
    };
}

function validationSchema() {
    return {
        Nombre: Yup.string().required(true),
    };
}
