import React, { useEffect, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTipoReunionMeet } from '../../hooks';
import { TableTipoReunion } from '../../components/Skymeet/TableTipoReunion';
import { Dropdown } from 'primereact/dropdown';

export function TipoReunionPage() {
    const [refresh, setRefresh] = useState(false);
    const [idTipoReunion, setIdTipoReunion] = useState(0);

    const toast = useRef();

    const { saveTipoReunion, getTipoReunion, listTipoReunion, loadingTipoReunion, editTipoReunion } = useTipoReunionMeet();

    const formik = useFormik({
        initialValues: initialValues(),
        validationSchema: Yup.object(validationSchema()),
        onSubmit: async (formValue) => {

            if (idTipoReunion > 0){
                await editTipoReunion(formValue, idTipoReunion);
            }else{

                await saveTipoReunion(formValue);

            }

            toast.current.show({ severity: 'success', summary: 'Exito', detail: 'Tipo de reunión agregada correctamente' });

            onCleanField();

            onRefresh();
        },
        validate: () => {
            if (formik.errors.Nombre) {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debes completar todos los campos' });
            }
        },
        validateOnChange: false
    });

    const onRefresh = () => setRefresh((prev) => !prev);

    useEffect(() => {
        document.title = "Tipos de reuniones";
        (async () => {
            await getTipoReunion();
        })();
    }, [refresh]);

    const onCleanField = () => {
        formik.setFieldValue('Nombre', '');
        formik.setFieldValue('View', '');
        setSelectedView(null);
        setIdTipoReunion(0);
    };

    const [selectedView, setSelectedView] = useState(null);

    const onEditTipoReunion = (rowData) => {
        formik.setFieldValue('Nombre', rowData.Nombre);
        formik.setFieldValue('View', rowData.View);
        setSelectedView({ nameV: rowData.View});
        setIdTipoReunion([rowData.id]);
    };    

    
    const views = [
        { nameV: 'one_on_one' },
        { nameV: 'moms' },
        { nameV: 'huddle' },
        { nameV: 'followup' },
    ];

    const changeV = (v) => {
        formik.setFieldValue('View', v)
    }

    return (
        <>
            <Toast ref={toast} />
            <div className="grid">
                <div className="col-12">
                    <div className="card my-3">
                        <h5>Tipos de reuniones - Administrador</h5>
                        <p>Agrega los tipos de reuniones que pueden ser agendadas</p>
                        <p>Antes de crear un tipo de reunión, recuerda validar los ya existentes.</p>

                        <form onSubmit={formik.handleSubmit}>
                        <InputText id="View" name="View" value={formik.values.View} onChange={formik.handleChange} className="w-full5" disabled hidden  />
                            <div className="grid my-5">
                                <div className="col-4">
                                    <span className="p-float-label">
                                        <InputText id="Nombre" name="Nombre" value={formik.values.Nombre} onChange={formik.handleChange} className="w-full" />
                                        <label htmlFor="Nombre">
                                            Nombre del tipo de reunión <b style={{ color: 'red' }}>*</b>
                                        </label>
                                    </span>
                                </div>
                                <div className="col-4">
                                    <span className="p-float-label">
                                        {/* <InputText id="Vista" name="Vista" value={formik.values.Nombre} onChange={formik.handleChange} className="w-full" /> */}
                                        <Dropdown id="Vista" name="Vista" value={selectedView} onChange={(e) => {setSelectedView(e.value); changeV(e.value.nameV)}} options={views} optionLabel="nameV" 
                                            placeholder="Selecciona la vista" className="w-full md:w-100%" />
                                        <label htmlFor="Vista">
                                            Vista de la reunión <b style={{ color: 'red' }}>*</b>
                                        </label>
                                    </span>
                                </div>
                                {/* <div className="col-4">
                                    <span className="p-float-label">
                                    <Dropdown id="View" name="View" value={selectedView} onChange={(e) => setSelectedView(e.value)} options={views} optionLabel="nameV" 
                                        placeholder="Selecciona una view" className="w-full md:w-20rem" /> 
                                        <label htmlFor="View">
                                            View <b style={{ color: 'red' }}>*</b>
                                        </label>
                                    </span>
                                </div> */}
                                {/* <div className="col-4">
                                    <span className="p-float-label">
                                        <InputText id="Correlativo" name="Correlativo" keyfilter="int" value={formik.values.Correlativo} onChange={(e) => formik.setFieldValue('Correlativo', e.target.value)} className="w-full" />
                                        <label htmlFor="Correlativo">
                                            Correlativo <b style={{ color: 'red' }}>*</b>
                                        </label>
                                    </span>
                                </div> */}
                            </div>
                            <div className="grid">
                                <div className="col-12 text-right">
                                    
                                    {idTipoReunion > 0 ? (
                                        <>
                                            {/* <span className='mr-3'>Editando tipo de reunión</span> */}
                                            <Button label='Editar tipo de reunión' severity="warning" disabled>                                            
                                                &nbsp;
                                                <i className="pi pi-pencil"></i>
                                            </Button>
                                            &nbsp;
                                            {/* <span className='mr-3'> */}
                                            <Button label="" style={{minHeight: '100%' }} severity="danger" type="button" onClick={onCleanField}>
                                                <i className="pi pi-times"></i>
                                            </Button>
                                            {/* </span> */}
                                        </>
                                    ) : (
                                        // <span className='mr-3'>Nuevo tipo de reunión</span>
                                        <Button label='Nuevo tipo de reunión' severity="success" disabled>                                            
                                            &nbsp;
                                            <i className="pi pi-check"></i>
                                        </Button>
                                    )}
                                    &nbsp;
                                    <Button type="submit" severity="primary">
                                        Guardar tipo reunión
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            {loadingTipoReunion ? (
                <div className="grid">
                    <div className="col-12 text-center">
                        <i className="pi pi-spinner pi-spin" style={{ fontSize: '5rem' }}></i>
                    </div>
                </div>
            ) : (
                <TableTipoReunion listTipoReunionShow={listTipoReunion} editPadre={onEditTipoReunion} />
            )}
        </>
    );
}

function initialValues() {
    return {
        Nombre: '',
        View: ''
        // View: ''
    };
}

function validationSchema() {
    return {
        Nombre: Yup.string().required(true),
        View: Yup.string().required(true)
        // View: Yup.string().required(true),
    };
}
