import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";

export function TableExcepcion ({ listExcepcionShow, editPadre }) {
    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <DataTable value={listExcepcionShow} paginator rows={10} sortField="id" sortOrder={1} stripedRows showGridlines>
                        <Column header="Badge" field="Badge" sortable></Column>
                        <Column header="Nombre" field="NombreBadge" filter filterPlaceholder="Tipo reunión" sortable></Column>                        
                        <Column header="Tipo de excepción" field="TipoExcepcion" sortable></Column>
                        <Column header="Comentario" field="Comentario" sortable></Column>
                        <Column header="Estado" field="Estado" sortable></Column>
                        <Column header="FechaInicio" field="FechaInicio" sortable></Column>
                        <Column header="FechaFinal" field="FechaFinal" sortable></Column>
                        <Column header="IdUsuario" field="IdUsuario" sortable></Column>
                        
                        {/* <Column header="Cuenta" field="Cuenta" sortable></Column>
                        <Column header="Fecha de reunión" field="FechaExcepcion" sortable></Column> */}
                        {/* <Column header="Acciones" body={accionesTemplate}></Column> */}
                    </DataTable>
                </div>
            </div>
        </div>
    )
}